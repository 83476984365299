// Customizable Area Start
import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName,
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";

export const configJSON = require("./config");

export interface Props {
  navigation: any;
  id: string;
  isNewUser?: boolean;
  isMemberUser?: boolean;
  isCreatorUser?: boolean;
  isNotificationAPI?: boolean;
}

interface S {
  anchorElCountry: any,
  selectedCountry: string,
  anchorElLanguage: any,
  selectedLanguage: string,
  selectedGender:string,
  anchorElAgeGroup:any,
  anchorElGender:any,
  selectedAgeGroup: string,
  notificationInput:any,
  heading: string;
  time: string;
  image: string;
  profileIconChange:any;
  anchorRef:any;
  isOpenNotificationModel: boolean;
  coverPhotos: any[];
  selectedCoverPhoto: any;
  ImageErrorMsg: any;
  buttonStatus: boolean;
  loginType: string;
  signUpType: string;
  anchorElLogin: any;
  anchorElSignUp: any;
  isHovered: boolean;
  isHoveredSignUp: boolean;
  searchInput: string;
  communityNames: any[];
  loggedInAs: string | null;
  searchInputAPI: string;
  showLogo:boolean;
  showSearchBar:boolean;
  showNavbarLinks:boolean;
  notificationList: any[];
}

interface SS {
  id: any;
}

export default class NavigationHeaderController extends BlockComponent<
  Props,
  S,
  SS
> {
  anchorRef: HTMLButtonElement | null = null;
  
  getSearchAPIId: string = "";
  getNotificationAPIId: string = "";
  updateProfileAPIId: string = "";

  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);

    this.subScribedMessages = [
      getName(MessageEnum.AccoutLoginSuccess),
      getName(MessageEnum.SessionResponseMessage),
      getName(MessageEnum.RestAPIResponceMessage),
    ];

    this.state = {
      anchorElCountry: null,
      selectedCountry: 'UK',
      anchorElLanguage: null,
      selectedLanguage: 'English',
      selectedGender:"Male",
      anchorElAgeGroup: null,
      anchorElGender:null,
      selectedAgeGroup: '18-24',
      notificationInput:"",
      heading: "",
      time: "",
      image: "",
      profileIconChange:null,
      anchorRef: null,
      isOpenNotificationModel: false,
      coverPhotos: [],
      selectedCoverPhoto: "",
      ImageErrorMsg: "",
      buttonStatus: false,
      loginType: "",
      signUpType:"",
      anchorElLogin: null,
      anchorElSignUp: null,
      isHovered: false,
      isHoveredSignUp: false,
      searchInput: "",
      communityNames: [],
      loggedInAs: null,
      searchInputAPI: "",
      showLogo:true,
      showSearchBar:true,
      showNavbarLinks:true,
      notificationList: [],
    };
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
  }

  async receive(from: string, message: Message) {
    const apiRequestCallId = message.getData(
      getName(MessageEnum.RestAPIResponceDataMessage)
    );
    const responseJson = message.getData(
      getName(MessageEnum.RestAPIResponceSuccessMessage)
    );
    if (apiRequestCallId === this.getSearchAPIId) {
			if (responseJson?.data) {
        this.setState({...this.state, communityNames: responseJson.data });
      }
      if (responseJson?.data) {
        const responseData = responseJson.data;

        this.setState({
          heading: responseData.headings,
          time: responseData.formatTime,
          image: responseData.image,
        });
      }
    }
  }

toggleNotificationModel = () => {
  this.setState((prev) => ({
    isOpenNotificationModel: !prev.isOpenNotificationModel,
  }));
};

handleSelectImage = (event: any) => {
  this.setState({ selectedCoverPhoto: event },()=>{this.handleButtonStatus()})
}

handleAddCoverImage = (e: any) => {
  let newCover = e.target.files[0]
  this.setState({ coverPhotos: [...this.state.coverPhotos, { Img: newCover }], ImageErrorMsg: ""},()=>{
    this.handleButtonStatus();
  })
}

handleButtonStatus = () => {
  let data = this.state
 
  if (data.selectedCoverPhoto.length !== 0) {
    this.setState({ buttonStatus: true })
  } else {
    this.setState({buttonStatus:false})
  }
}

  handleClearSearch = () => {
    this.setState({ searchInput: "" });
  };

  handleMouseEnterSignUp = () => {
    this.setState({ isHoveredSignUp: true });
  };

  handleMouseLeaveSignUp = () => {
    this.setState({ isHoveredSignUp: false });
  };

  handleMouseHover = (isHovered: boolean) => {
    this.setState({ isHovered: isHovered });
  };

  handleLoginSignUpClick = (event: any, type: string) => {
    this.setState({
      anchorElLogin: type === "login" ? event?.currentTarget : null,
      anchorElSignUp: type === "signUp" ? event?.currentTarget : null,
    });
  };

  handleClose = () => {
    this.setState({ anchorElLogin: null, anchorElSignUp: null });
  };

  OnLoginSignUp = (type: string, value?: any) => {
    const loginSignUpMessage = new Message(getName(MessageEnum.NavigationMessage));
    loginSignUpMessage.addData(getName(MessageEnum.NavigationTargetMessage), type);
    loginSignUpMessage.addData(getName(MessageEnum.NavigationPropsMessage), this.props);
    this.send(loginSignUpMessage);
    if(value) {
      localStorage.setItem("userType", value);
    }
  };

  handleInputChangeAPI = (e: any) => {
    const searchInput = e.target.value;
    this.setState({...this.state, searchInput: searchInput});
    const header = {
      "Content-Type": configJSON.validationApiContentType,
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.getSearchAPIId = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.serchAPIEndPoint+searchInput
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.validationApiMethodType
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
  };

  handleSuggestionClick = (item: any) => {
    this.setState(item);
  };
  
  handleClearSearchBar = () => {
    this.setState({ searchInput: '', communityNames: [] });
  };
}
// Customizable Area End