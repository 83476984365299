import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName,
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";

export const configJSON = require("./config");
export interface Props {
  navigation: any;
  id: string;
  isNewUser?: boolean;
}

interface S {
    userProfileDetails: any;
    isOpenProfileModel: boolean;
    isEditOpen: boolean;
		countryCodeList: any[];
}

interface SS {
  id: any;
}

export default class UserProfileDetailsModalController extends BlockComponent<
  Props,
  S,
  SS
> {

  getProfileDetailsApiId: string = "";
  getCountryCodeApiId: string = "";  

  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);
    this.subScribedMessages = [
        getName(MessageEnum.RestAPIResponceMessage),
      ];

    this.state = {
        userProfileDetails: null,
        isOpenProfileModel: false,
        isEditOpen: false,
				countryCodeList: [],
    };

    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
  }

  async receive(from: string, message: Message) {
    const apiRequestCallId = message.getData(
      getName(MessageEnum.RestAPIResponceDataMessage)
    );
    const responseJson = message.getData(
      getName(MessageEnum.RestAPIResponceSuccessMessage)
    );
    if (apiRequestCallId === this.getProfileDetailsApiId) {
      if (responseJson?.data) {
        this.setState({ ...this.state, userProfileDetails: responseJson.data });
      }
    }
		if (apiRequestCallId === this.getCountryCodeApiId) {
			if (responseJson?.data) {
				this.setState({...this.state, countryCodeList: responseJson.data });
			}
		}
  }


  toggleEditModel = () => {
    this.setState((prev) => ({
        isEditOpen: !prev.isEditOpen,
    }))
  };

  toggleProfileModel = () => {
    this.setState((prev) => ({
      isOpenProfileModel: !prev.isOpenProfileModel,
    }), () => {
      if (this.state.isOpenProfileModel) {
        this.getProfileDetails();
				this.getCountryCodeList();
      }
    });
  };

  getProfileDetails = () => {
    const header = {
      token: localStorage.getItem("authToken"),
      "Content-Type": configJSON.validationApiContentType,
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.getProfileDetailsApiId = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.updateProfileAPIEndPoint
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.validationApiMethodType
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
  };

	getCountryCodeList = () => {
    const header = {
      "Content-Type": configJSON.validationApiContentType,
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.getCountryCodeApiId = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.getCountryCodeAPIEndPoint
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.validationApiMethodType
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
  };
}
