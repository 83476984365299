import React from "react";

import {
  // Customizable Area Start
  Grid,
  Box,
  Typography,
  Button,
  ButtonProps,
  styled,
  Paper
  // Customizable Area End
} from "@material-ui/core";

// Customizable Area Start
import LandingPageController, { Props } from "./LandingPageController";
import {
  zilion,
  image_01,
  image_02,
  image_03,
  image_04,
  image_05,
  aboutUsArrow
} from "./assets";
import { createTheme } from "@material-ui/core/styles";
import { ThemeProvider } from "@material-ui/styles";
import AppleIcon from "@material-ui/icons/Apple";
import PlayArrowIcon from "@material-ui/icons/PlayArrow";
import KeyBenefits from "./KeyBenefits/KeyBenefits.web";
import {CarouselWithStyles} from "./Carouselcomponent/Carousel.web";
import { ReviewsWithStyles } from "./Reviews.web";
import NavigationHeader from "../../navigationmenu/src/NavigationHeader.web";
import ContentCarousel from "./ContentCarousel/Carousel";
import { formatNumber } from "./AboutUsController";
import { Link } from 'react-router-dom';
// Customizable Area End

// Customizable Area Start
const theme = createTheme({
  typography: {
    fontFamily: ["Montserrat"].join(",")
  }
});

const FooterButtonLinks = styled(Button)<ButtonProps>(() => ({
  color: "#fff",
  textTransform: "capitalize",
  fontSize: "16px",
  padding: 0
}));

export const Footer = () => {
  return (
    <Box style={{ ...webStyle.wrapper, backgroundColor: "#282828", }}>
      <ThemeProvider theme={theme}>
        <Grid container style={webStyle.FooterContainer}>
          <Grid item xs={12} sm={4} >
            <Typography
              variant="h6"
              component="h6"
              style={webStyle.FooterLogoSection}
            >
              <img width="32px" height="32px" src={zilion} alt={"zillion logo"} />
              About Zillon
            </Typography>
            <Typography
              variant="body1"
              style={{
                fontSize: "14px"
              }}
            >
              Becoming a creator just takes less than 5 minutes and earning your 1st
              dollar is just a share away.
            </Typography>
          </Grid>
          <Grid item xs={12} sm={4} >
            <Typography
              variant="h6"
              component="h6"
              style={webStyle.FooterLinkSections}
            >
              Navigate
            </Typography>
            <Box display={"flex"}>
              <FooterButtonLinks>About us</FooterButtonLinks>
              <FooterButtonLinks style={{ marginLeft: "32px" }}>
                FAQ
              </FooterButtonLinks>
            </Box>
            <Box display={"flex"} marginTop={"16px"}>
              <FooterButtonLinks>For Creator</FooterButtonLinks>
              <FooterButtonLinks style={{ marginLeft: "32px" }}>
                Contact Us
              </FooterButtonLinks>
            </Box>
          </Grid>
          <Grid item xs={12} sm={4} >
            <Typography
              variant="h6"
              component="h6"
              style={webStyle.FooterLinkSections}
            >
              Social Media
            </Typography>
            <Box display={"flex"}>
              <FooterButtonLinks>Facebook</FooterButtonLinks>
              <FooterButtonLinks style={{ marginLeft: "32px" }}>
                LinkedIn
              </FooterButtonLinks>
            </Box>
            <Box display={"flex"} marginTop={"16px"}>
              <FooterButtonLinks>Instagram</FooterButtonLinks>
              <FooterButtonLinks style={{ marginLeft: "32px" }}>
                Twitter
              </FooterButtonLinks>
            </Box>
          </Grid>
          <Grid item xs={12}>
            <Box
              display="flex"
              justifyContent="center"
              alignItems="center"
              paddingTop={"32px"}
              style={{ opacity: "0.5" }}
            >
              <FooterButtonLinks>© Zilion 2023 - 2024</FooterButtonLinks>
              <FooterButtonLinks style={{ marginLeft: "32px" }}>
                Site Map
              </FooterButtonLinks>
            </Box>
          </Grid>
        </Grid>
      </ThemeProvider>
    </Box>
  );
};

const SignUpButtons = styled(Button)<ButtonProps>(() => ({
  color: "#3E01B9",
  backgroundColor: "#E8E8E8",
  width: "227px",
  height: "63px",
  borderRadius: "17.5px",
  textTransform: "capitalize",
  fontFamily: 'Montserrat',
  fontSize: "18px",
  fontStyle: 'normal',
  fontWeight: 600,
  lineHeight: '24px',
  letterSpacing: '0.123px',
  cursor: 'pointer',
  "&:hover": {
    backgroundColor: "#E8E8E8"
  }
}));

export const WaitingSection = ({ onSignUp }: { onSignUp: (type?: "creator" | "member") => void }) => {
  return (
    <Box style={webStyle.wrapper}>
      <ThemeProvider theme={theme}>
        <Grid container style={webStyle.waitingSection} spacing={2}>
          <Grid item xs={12} style={{ marginBottom: "24px" }}>
            <Typography variant="h3">What are you waiting for?</Typography>
            <Typography
              variant="subtitle1"
              style={{ marginTop: "16px", opacity: "0.5" }}
            >
              we have Creators who have long experience in their field. so very
              reliable when making materials and guiding you
            </Typography>
          </Grid>
          <Grid item xs={12} md={6}>
            <Paper style={webStyle.signupCreator}>
              <Typography variant="h3" style={webStyle.signupCreatorHeading}>
                Start your community now!
              </Typography>
              <Typography
                variant="subtitle1"
                style={{ marginTop: "20px", maxWidth: "500px" }}
              >
                Becoming a creator just takes less than 5 minutes and earning your
                1st dollar is just a share away.
              </Typography>

              <SignUpButtons variant="contained" style={{ marginTop: "56px" }} onClick={() => { onSignUp('creator') }}>
                Sign Up As Creator
              </SignUpButtons>
            </Paper>
          </Grid>
          <Grid item xs={12} md={6}>
            <Paper style={webStyle.signupMember}>
              <Typography variant="h3" style={webStyle.signupMemberHeading}>
                Find a community to match your needs!
              </Typography>
              <Typography
                variant="subtitle1"
                style={{ marginTop: "20px", maxWidth: "500px" }}
              >
                Becoming a creator just takes less than 5 minutes and earning your
                1st dollar is just a share away.
              </Typography>
              <SignUpButtons variant="contained" style={{ marginTop: "56px" }} onClick={() => { onSignUp('member') }}>
                Sign Up As Member
              </SignUpButtons>
            </Paper>
          </Grid>
        </Grid>
      </ThemeProvider>
    </Box >

  );
};



const AppStoreButton = styled(Button)<ButtonProps>(() => ({
  color: "#fff",
  backgroundColor: "#3E01B9",
  width: "227px",
  height: "63px",
  borderRadius: "24px",
  textTransform: "capitalize",
  paddingLeft: "24px",
  justifyContent: "flex-start",
  fontSize: "18px",
  "&:hover": {
    backgroundColor: "#3E01B9"
  }
}));
const PlayStoreButton = styled(Button)<ButtonProps>(() => ({
  color: "#fff",
  backgroundColor: "#B50B8F",
  width: "227px",
  height: "63px",
  borderRadius: "24px",
  textTransform: "capitalize",
  paddingLeft: "24px",
  justifyContent: "flex-start",
  fontSize: "18px",
  marginLeft: "16px",
  "&:hover": {
    backgroundColor: "#B50B8F"
  }
}));
export const PlayStoreUI = () => {
  return (
    <Box style={webStyle.wrapper}>
      <Grid container style={{ maxWidth: '1280px' }}>
        <Grid item xs={12} md={6} style={webStyle.playStoreLeftSection}>
          <img src={image_05} width={"100%"} height={"auto"} alt={"image1"} />
        </Grid>
        <Grid item xs={12} md={6} container spacing={2} >
          <Grid item xs={6}>
            <img src={image_04} width={"100%"} height={"auto"} alt={"image2"} />
          </Grid>
          <Grid item xs={6}>
            <img src={image_02} width={"100%"} height={"auto"} alt={"image2"} />
          </Grid>
          <Grid
            item
            xs={12}
            style={webStyle.playStoreDownloadApp}
          >
            <Typography variant="h3" style={{ fontSize: "32px" }}>
              Download our New App
            </Typography>
            <Typography variant="subtitle1" style={{ color: "#E8E8E8" }}>
              we have Creators who have long experience in their field. so very
              reliable when making materials and guiding you
            </Typography>
            <Box display="flex" paddingTop="60px">
              <AppStoreButton variant="contained" endIcon={<AppleIcon />}>
                App Store
              </AppStoreButton>
              <PlayStoreButton variant="contained" endIcon={<PlayArrowIcon />}>
                Play Store
              </PlayStoreButton>
            </Box>
          </Grid>

          <Grid
            item
            xs={6}
            container
            alignItems="flex-end"
          >
            <img src={image_03} width={"100%"} height={"auto"} alt={"image2"} />
          </Grid>
          <Grid
            item
            xs={6}
            container
            alignItems="flex-end"
          >
            <img src={image_01} width={"100%"} height={"auto"} alt={"image2"} />
          </Grid>
        </Grid>
      </Grid>
    </Box>
  );
};


export const AboutUs = ({ aboutData }: { aboutData: any }) => {
  return (
    <Grid
      item
      xs={3}
      style={{
        display: "flex",
      }}
    >
      <Box>
        <img
          style={{
            width: "78px",
            height: "78px",
            borderRadius: "76px",
            border: "0.852px solid rgba(0, 0, 0, 0.16)",
          }}
          src={aboutData?.image}
          alt={"label"}
        />
      </Box>
      <Box marginLeft="15px" paddingTop="10px">
        <Typography variant="h6" component="h6" style={webStyle.aboutUsName}>
          {aboutData?.name ?? ''}
        </Typography>
        <Typography variant="subtitle2" style={webStyle.aboutUsTitle}>{aboutData?.title ?? ''}</Typography>
      </Box>
    </Grid>
  );
};

export const InfoNumbersSection = ({
  member, creators, communities, community_posts
}: {
  member: { formattedNum: string; text: string; };
  creators: { formattedNum: string; text: string; };
  community_posts: { formattedNum: string; text: string; };
  communities: { formattedNum: string; text: string; };
}) => {
  return (
    <React.Fragment>
      <Grid
        item
        xs={6}
        style={{
          borderRight: "1px solid rgba(0, 0, 0,0.21)",
          borderBottom: "1px solid rgba(0, 0, 0,0.21)",
          paddingBottom: "16px",
        }}
      >
        <Typography variant="h4" component="h4">
          {member.text}
        </Typography>
        <Typography variant="subtitle1">Members</Typography>
      </Grid>
      <Grid
        item
        xs={6}
        style={{
          borderBottom: "1px solid rgba(0, 0, 0,0.21)",
          paddingBottom: "16px",
          paddingLeft: "32px",
        }}
      >
        <Box>
          <Typography variant="h4" component="h4">
            {creators.text}
          </Typography>

          <Typography variant="subtitle1">Creators</Typography>
        </Box>
      </Grid>
      <Grid
        item
        xs={6}
        style={{
          borderRight: "1px solid rgba(0, 0, 0,0.21)",
          paddingBottom: 0,
          paddingTop: "16px",
        }}
      >
        <Typography variant="h4" component="h4">
          {community_posts.text}
        </Typography>

        <Typography variant="subtitle1">Posts</Typography>
      </Grid>
      <Grid
        item
        xs={6}
        style={{
          paddingTop: "16px",
          paddingLeft: "32px",
        }}
      >
        <Box>
          <Typography variant="h4" component="h4">
            {communities.text}
          </Typography>

          <Typography variant="subtitle1">Communities</Typography>
        </Box>
      </Grid>
    </React.Fragment>
  );
};

export const InfoBox = ({ zilionDetail }: {
  zilionDetail: {
    data: any[];
    member: number;
    creators: number;
    community_posts: number;
    communities: number;
    landing_details: any[]
  }
}) => {
  return (
    <Box style={{ ...webStyle.wrapper, backgroundColor: "#1C1C1C" }}>
      <Grid container spacing={3} style={webStyle.infoContainer}>
        <Grid item xs={12} md={8}>
          <Paper style={webStyle.infoLeftPanel}>
            <Grid container justifyContent="space-between">
              <Grid item xs={6}>
                <Typography variant="h4" component="h4"
                  style={{
                    fontWeight: 600,
                    fontSize: '32px',
                    lineHeight: '40px',
                    letterSpacing: '0.123px'
                  }}>
                  {zilionDetail?.landing_details[0]?.title}
                </Typography>
                <Typography style={webStyle.aboutUsDescription}>
                  {zilionDetail?.landing_details[0]?.description}
                </Typography>
                <Typography
                  variant="h5"
                  component="h5"
                  style={webStyle.infoAboutUs}
                >
                  About Us
                  <img style={{ height: '24px', width: '24px', marginLeft: '8px' }} src={aboutUsArrow} alt={"community"} />
                </Typography>
              </Grid>
              <Grid item xs={5} container>
                <InfoNumbersSection
                  member={formatNumber(zilionDetail.member)}
                  creators={formatNumber(zilionDetail.creators)}
                  community_posts={formatNumber(zilionDetail.community_posts)}
                  communities={formatNumber(zilionDetail.communities)}
                />
              </Grid>
            </Grid>
            <Grid
              container
              justifyContent="space-between"
              style={{ paddingTop: "58px" }}
            >
              {zilionDetail.data.map((value: any, index: number) => {
                return <AboutUs key={index} aboutData={value?.attributes} />;
              })}
            </Grid>
          </Paper>
        </Grid>
        <Grid item xs={12} md={4} style={webStyle.infoRightPanel}>
          <Paper style={webStyle.infoCreatorCard}>
            <Typography variant="h6" component="h6" >
              <Box fontWeight={'bold'} display={'inline'}>
                For Creators
              </Box>
            </Typography>
            <Typography variant="body1" style={webStyle.infoRightPanelPara}>
              Kokushibo is one of the most powerful Demons around. Makes his power
              immense...
              <Box sx={webStyle.infoLearnMore}>
                <Link style={webStyle.linkTagStyle} to={"/landingpagecreator"}>
                  Learn More
                </Link>
              </Box>
            </Typography>
          </Paper>
          <Paper style={webStyle.infoMemberCard}>
            <Typography variant="h6" component="h6">
              <Box fontWeight={'bold'} display={'inline'}>
                For Members
              </Box>
            </Typography>
            <Typography variant="body1" style={webStyle.infoRightPanelPara}>
              Kokushibo is one of the most powerful Demons around. Makes his power
              immense...
              <Box sx={webStyle.infoLearnMore}>
                <Link style={webStyle.linkTagStyle} to={"/landingpagemember"}>
                  Learn More
                </Link>
              </Box>
            </Typography>
          </Paper>
        </Grid>
      </Grid>
    </Box>
  );
};

// Customizable Area End

export default class LandingPage extends LandingPageController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    this.displayCommunities = this.displayCommunities.bind(this)
    // Customizable Area End
  }

  // Customizable Area Start
  displayCommunities() {
    return <>
      {(this.state.communitiesList).map((data: any, index: number) => {
        return <ContentCarousel
          ContentHeader={data?.category_name ?? ""}
          carouselData={data?.communities ?? []}
          key={data?.category_name ?? index} />
      })}
    </>
  }

  // Customizable Area End

  render() {
    return (
      <Box style={webStyle.mainWrapper} >
        <ThemeProvider theme={theme}>
        <NavigationHeader navigation={this.props.navigation} id="" isNewUser={true} />
          <CarouselWithStyles navigation={undefined} id={""} />
          <ContentCarousel ContentHeader={"Featured Community"} carouselData={this.state.featuredCommunity} />
          <this.displayCommunities />
          <InfoBox zilionDetail={this.state.zilionInfoBoxDetails} />
          <ReviewsWithStyles type="main" id="reviews" navigation={undefined} />
          <PlayStoreUI />
          <KeyBenefits type="main" isCenter={false} />
          <WaitingSection onSignUp={this.OnSignUp} />
          <Footer />
        </ThemeProvider>
      </Box>
    );
  }
}

// Customizable Area Start
const webStyle = {
  mainWrapper: {
    maxWidth: "100%",
    margin: "0 0",
    backgroundColor: "#000"
  },
  wrapper: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    border: 'none',
    boxSizing: 'border-box'
  } as React.CSSProperties,
  paperStyle: {
    borderRadius: "24px",
    color: "#fff"
  },
  FooterContainer: {
    color: "#fff",
    padding: "16px 128px 16px 128px",
    maxWidth: '1280px'
  },
  FooterLogoSection: {
    display: "flex",
    alignItems: "center",
    marginBottom: "16px",
    fontSize: "14px"
  },
  FooterLinkSections: {
    opacity: "0.5",
    fontSize: "14px",
    marginBottom: "24px"
  },
  waitingSection: {
    color: "#fff",
    padding: "46px 56px 114px 56px",
    maxWidth: '1280px'
  },
  signupCreator: {
    borderRadius: "24px",
    color: "#fff",
    background: " #3E01B9",
    padding: "32px 96px 24px 48px",
  },
  signupCreatorHeading: {
    fontSize: "32px",
    maxWidth: "302px",
    textTransform: "capitalize"
  } as React.CSSProperties,
  signupMember: {
    borderRadius: "24px",
    color: "#fff",
    background: " #B50B8F",
    padding: "32px 96px 24px 48px",
  },
  signupMemberHeading: {
    fontSize: "32px",
    maxWidth: "390px",
    textTransform: "capitalize"
  } as React.CSSProperties,
  playStoreLeftSection: {
    paddingTop: "101px",
    paddingLeft: "154px",
    paddingBottom: "118px",
    paddingRight: "auto"
  } as React.CSSProperties,
  playStoreDownloadApp: {
    color: "#fff",
    paddingRight: "75px"
  },
  playStoreBottomImage: {
    display: "flex",
    alignItems: "end"
  } as React.CSSProperties,
  infoContainer: {
    padding: "74px 56px 0 56px",
    maxWidth: '1280px'
  } as React.CSSProperties,
  infoLeftPanel: {
    borderRadius: "24px",
    color: "#fff",
    background: " #3E01B9",
    textAlign: "left",
    padding: "32px 32px 24px 48px",
  } as React.CSSProperties,
  infoRightPanel: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-between",
    gap: '24px'
  } as React.CSSProperties,
  infoMemberCard: {
    borderRadius: "24px",
    background: " #E8E8E8",
    textAlign: "left",
    padding: "26px",
    color: "#000",
  } as React.CSSProperties,
  infoCreatorCard: {
    borderRadius: "24px",
    color: "#fff",
    background: " #B50B8F",
    textAlign: "left",
    padding: "26px",
  } as React.CSSProperties,
  infoRightPanelPara: {
    marginTop: '16px',
    fontFamily: 'Montserrat',
    fontSize: '14px',
    fontStyle: 'normal',
    fontWeight: 400,
    lineHeight: '20px',
    letterSpacing: '0.123px',
  } as React.CSSProperties,
  infoLearnMore: {
    fontWeight: 500,
    display: 'inline',
    lineHeight: '24px',
    cursor: 'pointer',
    fontSize: '16px'
  } as React.CSSProperties,
  infoAboutUs: {
    marginTop: "20px",
    fontSize: '18px',
    display: 'flex',
    alignItems: 'center',
    textTransform: 'uppercase'
  } as React.CSSProperties,
  aboutUsName: {
    fontFamily: 'Montserrat',
    fontSize: '13.638px',
    fontStyle: 'normal',
    fontWeight: 500,
    lineHeight: '20.457px',
    letterSpacing: '0.105px',
  } as React.CSSProperties,
  aboutUsTitle: {
    fontFamily: 'Montserrat',
    fontSize: '13.638px',
    fontStyle: 'normal',
    fontWeight: 400,
    lineHeight: '20.457px',
    letterSpacing: '0.105px',
  } as React.CSSProperties,
  aboutUsDescription: {
    fontFamily: 'Montserrat',
    fontSize: '16px',
    fontStyle: 'normal',
    fontWeight: 500,
    lineHeight: '24px',
    letterSpacing: '0.123px',
    maxWidth: "320px",
    marginTop: "12px"
  } as React.CSSProperties,
  linkTagStyle: {
    fontFamily: 'Montserrat',
    textDecoration: 'none',
    color: 'inherit'

  } as React.CSSProperties
};
// Customizable Area End
