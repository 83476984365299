// Customizable Area Start
import React from "react";
import {
  Button,
  Typography,
  InputAdornment,
  AppBar,
  IconButton,
  List,
  ListItem,
  Toolbar,
  Grid,
  Popper,
  InputBase,
  ListItemText,
  Paper,
  ClickAwayListener,
  Box,
} from "@material-ui/core";
import {
  notificationIcon,
  Logo,
  noNotificationIcon,
} from "./assets";
import ArrowDropDownIcon from "@material-ui/icons/ArrowDropDown";
import SearchIcon from "@material-ui/icons/Search";
import { createTheme, ThemeProvider } from "@material-ui/core/styles";
import { Link } from "react-router-dom";

import NavigationHeaderController, {
  Props
} from "./NavigationHeaderController";
import CloseIcon from '@material-ui/icons/Close';
import UserProfileDetailsModal from "../../user-profile-basic/src/UserProfileDetailsModal.web";

const showSearchBar = (isNewUser?: boolean, isMemberUser?: boolean) => {
  return (isNewUser || isMemberUser);
}

const setSearchPlaceHolder = (isNewUser?: boolean) => {
  return isNewUser ? "Search" : "Find a creator or a community";
}

const setSearchBarStyles = (isNewUser?: boolean) => {
  return isNewUser ? webStyle.SearchBarSize : webStyle.SearchBarSizeMember ;
}

const setMainAppBarColor = (isNewUser?: boolean) => {
  return isNewUser ? webStyle.MainAppBar : webStyle.MainAppBarMember ;
}

const setLogoImage = (isNewUser?: boolean) => {
  return isNewUser ? webStyle.LogoImg : webStyle.LogoImgMember;
}


const setSearchBarColor = (isNewUser?: boolean) => {
  return isNewUser ? webStyle.mainSearchBar : webStyle.mainSearchBarColorMember;
}


export default class NavigationHeader extends NavigationHeaderController {
  constructor(props: Props) {
    super(props);
  }
 
  render() {
    const {isNewUser, isMemberUser } = this.props;
    const {
      anchorElLogin,
      anchorElSignUp,
      isHovered,
      searchInput,
      communityNames,
      isOpenNotificationModel,
      notificationList,
    } = this.state;
    
    const filteredData: any[] = communityNames
      .filter((item: any) =>
        item.attributes.name.toLowerCase().startsWith(searchInput.toLowerCase())
      )
      .slice(0, 5);

    return (
      <ThemeProvider theme={theme}>
        <AppBar position="static" style={setMainAppBarColor(isNewUser)}>
          <Toolbar style={{ display: 'flex', justifyContent: 'space-between' }} >
          <div style={{ display: 'flex', alignItems: 'center' }}>
            <Typography variant="h6" style={webStyle.LogoTypography}>
              <Link to="/" style={webStyle.LogoLink}>
                <img src={Logo} alt="Logo" style={setLogoImage(isNewUser)} />
              </Link>
            </Typography>
            </div>
            
            <Box display="flex" alignItems="center" justifyContent='space-between'>
              {showSearchBar(isNewUser, isMemberUser) && 
              <Grid container  style={setSearchBarStyles(isNewUser)}>
              <Grid item xs={12} >
                <InputBase
                  data-test-id="search-input"
                  placeholder={setSearchPlaceHolder(isNewUser)}
                  fullWidth
                  value={searchInput}
                  onChange={this.handleInputChangeAPI}
                  startAdornment={
                    <InputAdornment position="start">
                      <IconButton size="small">
                        <SearchIcon style={webStyle.SearchBarSearchIcon} />
                      </IconButton>
                    </InputAdornment>
                    
                  }
                  endAdornment={
                    this.state.searchInput.length > 0 && (
                      <InputAdornment position="end">
                        <IconButton
                          size="small"
                          data-test-id="clear-search"
                          onClick={this.handleClearSearchBar}
                        >
                          <CloseIcon style={webStyle.clearSearch} fontSize="small" />
                        </IconButton>
                      </InputAdornment>
                    )
                  }
                  style={setSearchBarColor(isNewUser)}
                />
                
                {this.state.searchInput.length > 0 && filteredData.length > 0 && (
                  <Paper style={webStyle.searchPaper}>
                    <List style={webStyle.searchList}>
                      {filteredData.map((item: any, i: number) => (
                        <ListItem
                          style={webStyle.MainItemSearch}
                          key={i}
                          value={item.attributes.name}
                          onClick={() => this.handleSuggestionClick(item)}
                        >
                          <ListItemText primary={item.attributes.name} />
                        </ListItem>
                      ))}
                    </List>
                  </Paper>
                  
                )}
              </Grid>
            </Grid>}

            {isNewUser && <>
              <Link href="#" style={webStyle.FaqLink} to="/faq">
              FAQ
            </Link>
            
            <Link to="/LandingPageCreator" style={webStyle.ForAllLink}>
              For Creator
            </Link>
            <Link to="/AboutUs" style={webStyle.AboutUsLink}>
              About us
            </Link>

            <Button
              data-test-id="login-button"
              variant="contained"
              onClick={(e: any) => this.handleLoginSignUpClick(e, "login")}
              style={{
                ...webStyle.LoginButton,
                backgroundColor: isHovered ? "#00009B" : "#222222",
              }}
              onMouseEnter={() => this.handleMouseHover(true)}
              onMouseLeave={() => this.handleMouseHover(false)}
              endIcon={<ArrowDropDownIcon />}
            >
              Login
            </Button>

            <Popper
              data-test-id="loginButton"
              open={Boolean(anchorElLogin)}
              anchorEl={anchorElLogin}
              placement="bottom-start"
              transition
              disablePortal
              style={webStyle.LoginPopper}
            >
              <ClickAwayListener
                onClickAway={this.handleClose}
              >
                <Paper style={webStyle.LoginPaper}>
                  <List>
                    <ListItem
                      data-test-id="creator-login"
                      button
                      onClick={() => this.OnLoginSignUp("LogIn")}
                      style={webStyle.LoginListItemCreator}
                    >
                      <ListItemText primary="Creator" />
                    </ListItem>
                    <ListItem
                      button
                      onClick={() => this.OnLoginSignUp("LogIn")}
                      style={webStyle.LoginListItemMember}
                    >
                      <ListItemText primary="Member" />
                    </ListItem>
                  </List>
                </Paper>
              </ClickAwayListener>
            </Popper>

            <Button
              data-test-id="signUpButton"
              variant="contained"
              onClick={(e: any) => this.handleLoginSignUpClick(e, "signUp")}
              style={{
                ...webStyle.SignUpButton,
              }}
              onMouseEnter={this.handleMouseEnterSignUp}
              onMouseLeave={this.handleMouseLeaveSignUp}
              endIcon={<ArrowDropDownIcon />}
            >
              Sign Up
            </Button>

            <Popper
              open={Boolean(anchorElSignUp)}
              anchorEl={anchorElSignUp}
              placement="bottom-start"
              transition
              disablePortal
              style={webStyle.SignUpPopper}
            >
              <ClickAwayListener
                onClickAway={this.handleClose}
              >
                <Paper style={webStyle.SignUpPaper}>
                  <List>
                    <ListItem
                      button
                      onClick={() => this.OnLoginSignUp("SignUpCreator", "Creator")}
                      style={webStyle.SignUpListItemCreator}
                    >
                      <ListItemText primary="Creator" />
                    </ListItem>
                    <ListItem
                      button
                      data-test-id="member-signup"
                      onClick={() => this.OnLoginSignUp("SignUpMember", "Member")}
                      style={webStyle.SignUpListItemMember}
                    >
                      <ListItemText primary="Member" />
                    </ListItem>
                  </List>
                </Paper>
              </ClickAwayListener>
            </Popper>
              </>} 
              {!isNewUser && <>
                <>
                  <Box style={webStyle.notificationIcon}>
                  <Button data-test-id="notification-icon" ref={(ref) => (this.anchorRef = ref)} onClick={this.toggleNotificationModel}>
                    <img src={notificationIcon} alt="Notification"  />
                  </Button>
                  </Box>
                  
                  {isOpenNotificationModel && <Popper
                    open={isOpenNotificationModel}
                    anchorEl={this.anchorRef}
                    placement="bottom-end"
                    disablePortal={false}
                    modifiers={{
                      flip: {
                        enabled: false,
                      },
                      preventOverflow: {
                        enabled: false,
                        boundariesElement: 'scrollParent',
                      },
                      arrow: {
                        enabled: false,
                      },
                    }}
                  >
                    <Paper style={{ width: 556, height: 471, borderRadius: 10, backgroundColor: "#292929" }}>
                      <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', padding: '4px 16px', borderBottom: "0.5px solid rgba(155, 155, 155, 0.13)" }}>
                        <Typography variant="h6" style={{ color: "#fff" }}>Notifications</Typography>
                        <IconButton data-test-id="close-notification-icon" onClick={this.toggleNotificationModel}>
                          <CloseIcon style={{ color: "#fff" }} />
                        </IconButton>
                      </div>
                      {!notificationList.length ? 
                      <Box display="flex" alignItems="center" justifyContent="center" paddingTop="30px">
                        <Box>
                          <Box display="flex" alignItems="center" justifyContent="center">
                            <img height={120} src={noNotificationIcon} alt="no-notifications" />
                          </Box>
                          <Box paddingTop={"10px"} fontSize={"18px"} color="#fff" fontWeight={600}>No notifications yet</Box>
                        </Box>
                      </Box> : <div />}
                    </Paper>
                  </Popper>}
                </>
                <div>
                 <UserProfileDetailsModal id="" navigation={this.props.navigation}/>
                </div>
              </>}
              </Box>
          </Toolbar>
        </AppBar>
      </ThemeProvider>
    );
  }
}

const theme = createTheme({
  palette: {
    primary: {
      main: "#fff",
      contrastText: "#fff",
    },
  },
  typography: {
    h6: {
      fontWeight: 500,
    },
    subtitle1: {
      margin: "20px 0px",
    },
  },
});

const webStyle = {

  
  MainAppBar: { backgroundColor: "#000000", width: "100%", height: "64px" },
  MainAppBarMember: {backgroundColor: "#212121"},
  LogoTypography: {
    flexGrow: 0,
    color: "#000",
    marginLeft: "56px",
  },
  LogoImg: {
    width: "50px",
    cursor: "pointer",
  },
  LogoImgMember: {
    width:"40px",
    height:"48px",
    cursor: "pointer",
  },
  LogoLink: { textDecoration: "none", color: "inherit" },

  SearchBarSize: { width: 200, height: 45, },
  SearchBarSizeMember: {width: 500, height: 45,},
  SearchBarSearchIcon: { color: "#E8E8E8", width:"0.8em" },
  clearSearch: {backgroundColor:"white", fontSize:"15px", borderRadius:"50px"},
  searchList:{ paddingTop:"3px", borderRadius: 10,},
  searchPaper:{ 
    backgroundColor:"black",
    borderRadius: 10,
  },
  MainItemSearch:{
    zIndex: 9999,
    backgroundColor:"#222222",
    color:"#787977",
    height: "36px",
  },
  mainSearchBar: {
    display: "flex",
    justifyContent: "end",
    borderRadius: 10,
    fontFamily: "Montserrat",
    backgroundColor: "#222222",
    padding: "5px 15px",
    color: "#E8E8E8",
    height: "36px",
    marginTop: "4px",
  },
  mainSearchBarColorMember: {
    display: "flex",
    justifyContent: "end",
    borderRadius: 10,
    fontFamily: "Montserrat",
    padding: "5px 15px",
    color: "#E8E8E8",
    height: "36px",
    marginTop: "4px",
    backgroundColor: "#080808",
  },
  FaqLink: {
    fontFamily: "Montserrat",
    margin: "0px 24px 0px 58px",
    color: "#787977",
    textDecoration: "none",
    transition: "color 0.3s",
  },
  ForAllLink: {
    fontFamily: "Montserrat",
    margin: "0px 14px",
    color: "#787977",
    textDecoration: "none",
    transition: "color 0.3s",
  },
  AboutUsLink: {
    fontFamily: "Montserrat",
    margin: "0px 58px 0px 24px",
    color: "#787977",
    textDecoration: "none",
    transition: "color 0.3s",
  },
  LoginButton: {
    marginRight: "5px",
    minWidth: "100px",
    width: "120px",
    height: "36px",
    color: "#fff",
    fontFamily: "Montserrat",
    borderRadius: "10px",
    transition: "background-color 0.3s",
    textTransform: "capitalize" as any,
  },
  LoginPopper: {
    zIndex: 9999,
    marginTop: "5px",
    backgroundColor: "#240169",
    borderRadius: "10px",
    width: "120px",
    height: "91px",
  },
  LoginPaper: {
    backgroundColor: "#240169",
    borderRadius: "10px",
    width: "120px",
    height: "91px",
  },
  LoginListItemCreator: {
    minWidth: "100px",
    backgroundColor: "#240169",
    color: "#fff",
    width: "110px",
    height: "25px",
    padding: "20px",
  },
  LoginListItemMember: {
    minWidth: "100px",
    backgroundColor: "#240169",
    color: "#fff",
    width: "110px",
    height: "25px",
    padding: "20px",
  },
  SignUpButton: {
    marginLeft: "5px",
    minWidth: "100px",
    fontFamily: "Montserrat",
    borderRadius: "10px",
    color: "#fff",
    marginRight: "56px",
    textTransform: "capitalize" as any,
    backgroundColor:"#00009B",
  },
  SignUpPopper: {
    zIndex: 9999,
    marginTop: "5px",
    backgroundColor: "#240169",
    borderRadius: "10px",
  },
  SignUpPaper: {
    backgroundColor: "#240169",
    borderRadius: "10px",
    width: "120px",
    height: "91px",
  },
  SignUpListItemCreator: {
    minWidth: "100px",
    backgroundColor: "#240169",
    color: "#fff",
    width: "110px",
    height: "25px",
    padding: "20px",
  },
  SignUpListItemMember: {
    minWidth: "100px",
    backgroundColor: "#240169",
    color: "#fff",
    width: "110px",
    height: "25px",
    padding: "20px",
  },
  profileIcon:{
    width: "45px",
    cursor: "pointer",
    height: "45px",
    borderRadius:"120px",
    marginLeft:"14px",
    marginRight:"44px",
  },
  profileIconMember: {
    width: "45px",
    cursor: "pointer",
    height: "45px",
    borderRadius:"120px",
    marginLeft:"10px",
    marginRight:"22px"},
  notificationIcon:{
    width: "40px",
    cursor: "pointer",
    height: "40px",
    marginLeft:"350px",
    marginRight:"14px",
    display:"flex",
  },
  noNotificationIcon:{
    width: 103, height: 103,
  },
heading: {
  fontFamily: "Inter",
  fontSize: "24px",
  fontStyle: "normal",
  fontWeight: 700,
  lineHeight: "24px",
} as React.CSSProperties,
uploadBaseWrapper: {
  backgroundColor: "#210063",
  borderRadius: "5px",
  width: "auto",
  height: "20%",
  textAlign: "center",
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
} as const,
selectedCoverPhoto: {
  height: "200px",
  width: "200px",
  borderRadius: "50%",
  margin: "30px 0px"
},
modalContentImage: {
  height: "200px",
  width: "200px",
  backgroundColor: "#FFF",
  borderRadius: "50%",
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  margin: "30px 0px"
},
};

// Customizable Area End
