export const imgPasswordVisible = require("../assets/ic_password_visible.png");
export const imgPasswordInVisible = require("../assets/ic_password_invisible.png");
export const Analytics = require("../assets/Analytics.png");
export const image_logo = require("../assets/image_logo.png");
export const Communities = require("../assets/Communities.png");
export const Events = require("../assets/Events.png");
export const Home = require("../assets/Home.png");
export const Messages = require("../assets/Messages.png");
export const Notifications = require("../assets/Notifications.png");
export const SettingsImg = require("../assets/SettingsImg.png");
export const Cover = require("../assets/Cover.png")
export const BottomIcon = require("../assets/bottomIcon.png")
export const CardIcon = require("../assets/cardIcon.png")
export const LockIcon = require("../assets/Lock.png")
export const Outlined = require("../assets/Outlinedicon.png")
export const CrossIcon = require("../assets/Cross.png")
export const GroupProfileAdd = require("../assets/groupProfileIcon.png")
export const ImageDrop = require("../assets/DropImage.png")
export const CheckedIcon = require("../assets/CheckedIcon.png")
export const UncheckedIcon = require("../assets/UncheckedIcon.png")
export const ChatcardIcon = require("../assets/ChatIcon.png")

export const statusIcon  = require("../assets/image_04.png")
export const imgMessageIcon = require("../assets/imgMessageIcon.png")
export const imgNewPostIcon = require("../assets/imgNewPostIcon.png")
export const playBtn = require("../assets/playBtn.png")
export const CheckIcon = require("../assets/CheckIcon.png")
export const tick2 = require("../assets/tick2.png");
export const dollar = require("../assets/dollar.png");