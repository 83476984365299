import React from "react";

// Customizable Area Start
import {
  Box,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  styled,
  Collapse
} from "@material-ui/core";
import {
  Dropdown,
  Home,
  Messages,
  SettingsImg,
  Events,
  Communities,
  Analytics,
  Vector
} from "./assets";

const CustomText = styled(ListItemText)({
  '& .MuiTypography-displayBlock': {
    fontSize: 20
  },
  '& .MuiTypography-body1':{
    fontFamily:"Montserrat",
    letterSpacing: "-1px"
  }
})
const CustomTextDrk = styled(ListItemText)({
  '& .MuiTypography-displayBlock': {
    fontSize: 20,
    fontWeight: 700,
    letterSpacing: "-1px",
  },
  '& .MuiTypography-body1':{
    fontFamily:"Montserrat"
  }
})
const CustomList = styled(List)({
  '& .MuiListItem-root:hover': {
    background: '#3F3F3F3B',
    cursor: 'pointer',
    borderRadius: "10px",
    '& .MuiTypography-displayBlock': {
      fontWeight: 700,
      letterSpacing: "-1px"
    },
  },
})
const CustomDropdownText = styled(List)({
  '& .MuiTypography-body1':{
    marginLeft:"6rem"
  }
})
const CustomIcon = styled(ListItemIcon)({
  '& .MuiListItemIcon-root': {
    minWidth: '10px'
  }
})

// Customizable Area End

import NavigationMenuController, {
} from "./NavigationMenuController";

export default class CreatorNavigationMenu extends NavigationMenuController {

  render() {
    return (
      <Box style={webStyle.creator_menu}>
        <CustomList style={webStyle.creator_menu_ul}>
          <ListItem
            data-testid="test_Home"
            style={window.location.pathname.includes("Home") ? webStyle.creator_menu_li_active : webStyle.creator_menu_li}
            onClick={() => { this.creatorNavigatetoHome() }}
          >
            <ListItemIcon style={webStyle.creator_list_icom}>
              <img src={Home} style={webStyle.creator_drawerItemIcon} />
            </ListItemIcon>
            {window.location.pathname.includes("Home")
              ? <CustomTextDrk style={webStyle.creator_drawerItemtext}>Home</CustomTextDrk>
              : <CustomText style={webStyle.creator_drawerItemtext}>Home</CustomText>}
          </ListItem>
          <ListItem
            data-testid="test_Community"
            style={window.location.pathname.includes("Communities") ? webStyle.creator_menu_li_active : webStyle.creator_menu_li}
            onClick={() => { this.handleOpenCommunityDropdown() }}
          >
            <ListItemIcon style={webStyle.creator_list_icom}>
              <img src={Communities} style={webStyle.creator_drawerItemIcon} />
            </ListItemIcon>
            {window.location.pathname.includes("Communities")
              ? <CustomTextDrk style={webStyle.creator_drawerItemtext}>Communities</CustomTextDrk>
              : <CustomText style={webStyle.creator_drawerItemtext}>Communities</CustomText>}
            <img src={Dropdown} style={webStyle.creator_drawerItemIcon} />
          </ListItem>
          <Collapse in={this.state.showDropdown} timeout={400}>
            <List>
              <ListItem
                style={webStyle.creator_deopdown_li}
              >
                <CustomDropdownText  style={{...webStyle.creator_drawerItemtext,marginLeft:"60px"}}>Dropdown1</CustomDropdownText>
              </ListItem>
              <ListItem
                style={webStyle.creator_deopdown_li}
              >
                <CustomDropdownText  style={{...webStyle.creator_drawerItemtext,marginLeft:"60px"}}>Dropdown2</CustomDropdownText>
              </ListItem>
              <ListItem
                style={webStyle.creator_deopdown_li}
              >
                <CustomDropdownText  style={{...webStyle.creator_drawerItemtext,marginLeft:"60px"}}>Dropdown3</CustomDropdownText>
              </ListItem>
            </List>
          </Collapse>
          <ListItem
            data-testid="test_Messages"
            style={window.location.pathname.includes("Messages") ? webStyle.creator_menu_li_active : webStyle.creator_menu_li}
            onClick={() => { this.creatorNavigatetoMessage() }}
          >
            <ListItemIcon style={webStyle.creator_list_icom}>
              <img src={Messages} style={webStyle.creator_drawerItemIcon} />
            </ListItemIcon>
            {window.location.pathname.includes("Messages")
              ? <CustomTextDrk style={webStyle.creator_drawerItemtext}>Messages</CustomTextDrk>
              : <CustomText style={webStyle.creator_drawerItemtext}>Messages</CustomText>}
          </ListItem>
          <ListItem
            data-testid="test_Events"
            style={window.location.pathname.includes("Events") ? webStyle.creator_menu_li_active : webStyle.creator_menu_li}
            onClick={() => { this.creatorNavigatetoEvents() }}
          >
            <ListItemIcon style={webStyle.creator_list_icom}>
              <img src={Events} style={webStyle.creator_drawerItemIcon} />
            </ListItemIcon>
            {window.location.pathname.includes("Events")
              ? <CustomTextDrk style={webStyle.creator_drawerItemtext}>Events</CustomTextDrk>
              : <CustomText style={webStyle.creator_drawerItemtext}>Events</CustomText>}
          </ListItem>
          <ListItem
            data-testid="test_Create"
            style={window.location.pathname.includes("Create") ? webStyle.creator_menu_li_active : webStyle.creator_menu_li}
            onClick={() => { this.creatorNavigatetoCreate() }}
          >
            <ListItemIcon style={webStyle.creator_list_icom}>
              <img src={Vector} style={{width:"26px", height:"26px"}} />
            </ListItemIcon>
            {window.location.pathname.includes("Create")
              ? <CustomTextDrk style={webStyle.creator_drawerItemtext}>Create</CustomTextDrk>
              : <CustomText style={webStyle.creator_drawerItemtext}>Create</CustomText>}
          </ListItem>
          <ListItem
            data-testid="test_Analytics"
            style={window.location.pathname.includes("Analytics") ? webStyle.creator_menu_li_active : webStyle.creator_menu_li}
            onClick={() => { this.creatorNavigatetoAnalytics() }}
          >
            <ListItemIcon style={webStyle.creator_list_icom}>
              <img src={Analytics} style={{width:"14px", height:"18px",marginLeft:"5px"}} />
            </ListItemIcon>
            {window.location.pathname.includes("Analytics")
              ? <CustomTextDrk style={webStyle.creator_drawerItemtext}>Analytics</CustomTextDrk>
              : <CustomText style={webStyle.creator_drawerItemtext}>Analytics</CustomText>}
          </ListItem>
          <ListItem
            data-testid="test_Settings"
            style={window.location.pathname.includes("Settings") ? webStyle.creator_menu_li_active : webStyle.creator_menu_li}
            onClick={() => { this.creatorNavigatetoSettings() }}
          >
            <ListItemIcon style={webStyle.creator_list_icom}>
              <img src={SettingsImg} style={webStyle.creator_drawerItemIcon} />
            </ListItemIcon>
            {window.location.pathname.includes("Settings")
              ? <CustomTextDrk style={webStyle.creator_drawerItemtext}>Settings</CustomTextDrk>
              : <CustomText style={webStyle.creator_drawerItemtext}>Settings</CustomText>}
          </ListItem>
        </CustomList>
      </Box>
    );
  }
}



const webStyle = {
  creator_drawerItemIcon: {
    width: 32,
    height: 32
  },
  creator_drawerItemtext: {
    color: "#FFFFFF",
    fontWeight: 400,
    whiteSpace: "nowrap",
    overflow: "hidden",
    textOverflow: "ellipsis",
    fontFamily: "Montserrat",
    fontSize: "20px",
    letterSpacing: "0em",
  } as const,
  creator_menu: {
    backgroundColor: "#222222",
    width: "295px",
    '&:hover': {
      backgroundColor: "black",
    },
  },
  creator_menu_ul: {
    display: "flex",
    flexDirection: "column" as any,
    alignItems: "center"
  },
  creator_menu_li: {
    width: "245px",
    height: "57px",
  },
  creator_menu_li_active: {
    background: '#3F3F3F3B',
    cursor: 'pointer',
    width: "245px",
    height: "57px",
    borderRadius: "10px",
  },
  creator_list_icom: {
    minWidth: '45px'
  },
  creator_deopdown_li:{
    width: "245px",
    height: "57px",

  }
};
