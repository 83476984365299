import React from "react";
import {
    Button,
    Typography,
    IconButton,
    Box,
    Modal,
  } from "@material-ui/core";
import { createTheme } from "@material-ui/core/styles";
import UserProfileDetailsModalController, { Props } from "./UserProfileDetailsModalController";
import { USAflag, ageGroupIcon, arrowDownIcon, changePassword, earthIcon, editIcon, fullNameUserIcon, profileIcon, profileMessageIcon, userSmileFill } from "./assets";

const setProfileIcon = (isNewUser?: boolean) => {
    return isNewUser ? webStyle.profileIcon : webStyle.profileIconMember;
  }

const getCountryFlag = (userProfileDetails: any, countryCodeList: any[]) => {
    let flag = USAflag;
    const countryData = countryCodeList.find((item) => 
        item?.attributes?.country_code === userProfileDetails?.attributes?.country_code);
    if (countryData) {
        flag = countryData?.attributes?.emoji_flag
    }
    return flag;
}

export default class UserProfileDetailsModal extends UserProfileDetailsModalController {
  constructor(props: Props) {
    super(props);
  }

  render() {
    const {isNewUser } = this.props;
    const {
        userProfileDetails,
        isOpenProfileModel,
        countryCodeList,
        isEditOpen,
    } = this.state;
    return (
        <div>
                <Button data-test-id="profile-icon" onClick={this.toggleProfileModel} >
                    <img src={profileIcon} alt="Profile" style={setProfileIcon(isNewUser)} />
                </Button >
                {isOpenProfileModel && <Modal
                open={isOpenProfileModel}
                onClose={this.toggleProfileModel}
                style={{ overflowY: "scroll" }}
                disableScrollLock={false}
                >
               <div style={webStyle.SelectCoverImage}>
                <div
                style={{
                    backgroundColor: "#222",
                    padding: "5px",
                    borderRadius: "5px",
                }}
            >
                <div
                    style={{
                      margin: "2px",
                      height:"1066px",
                    }}
                >
                  <div style={{display:"flex", marginBottom:"42px"}}>
                  <img src={userSmileFill} alt="personalInfo" style={{marginRight:"20px",}}/>
                    <Typography style={webStyle.personalInfoHeading}>
                        Personal Information
                    </Typography>
                  </div>
                  <div style={webStyle.settingBaseWrapper}>
                      <div style={{ position: 'relative', height: "93px", width: "93px", borderRadius: "50%" }}>
                          <img data-test-id="close-profile-icon" src={profileIcon} width="100%" height="100%" style={{ borderRadius: "50%" }} />
                            <Button
                                data-test-id="edit-icon"
                                onClick={this.toggleEditModel}
                                style={{
                                    position: 'absolute',
                                    bottom: 0,
                                    right: 0,
                                    minWidth: '32px',
                                    height: '32px',
                                    display: 'flex',
                                    justifyContent: 'center',
                                    alignItems: 'center',
                                    gap: '8px',
                                    borderRadius: '8px',
                                    backgroundColor: '#fff',
                                }}
                                >
                                <img  src={editIcon} alt="Edit" style={{ width: '14px', height: '14px' }} />
                            </Button>
                            <Modal
                                open={isEditOpen}
                                data-test-id="close-edit-icon"
                                onClose={this.toggleEditModel}
                                style={{ overflowY: "scroll" }}
                                disableScrollLock={false}
                            >
                                <div style={webStyle.SelectCoverImage}>
                                    <div
                                        style={{
                                            backgroundColor: "#3118B5",
                                            padding: "5px",
                                            borderRadius: "5px",
                                            display: "flex",
                                            flexDirection: "column",
                                            alignItems: "center",
                                        }}
                                    >
                                        <div
                                            style={{
                                                margin: "2px",
                                                height: "200px",
                                                display: "flex",
                                                flexDirection: "column",
                                                justifyContent: "center",
                                            }}
                                        >
                                            <div>
                                                <Typography
                                                    style={{
                                                        fontFamily: "Montserrat",
                                                        fontSize: "32px",
                                                        fontStyle: "normal",
                                                        fontWeight: 400,
                                                        lineHeight: "40px",
                                                        textAlign: "center",
                                                        color: "#fff",
                                                    }}
                                                >
                                                    Profile Picture
                                                </Typography>
                                            </div>
                                            <div
                                                style={{
                                                    display: "flex",
                                                    justifyContent: "center",
                                                    alignItems: "center",
                                                    marginTop: "20px",
                                                }}
                                            >
                                                <Button
                                                    style={{
                                                        textAlign: "center",
                                                        fontFamily: "Montserrat",
                                                        fontSize: "24px",
                                                        fontStyle: "normal",
                                                        fontWeight: 500,
                                                        lineHeight: "40px",
                                                        letterSpacing: "1px",
                                                        textTransform: "uppercase",
                                                        color: "#fff",
                                                    }}
                                                >
                                                    Upload
                                                </Button>
                                                <div
                                                    style={{
                                                        width: "4px",
                                                        height: "62px",
                                                        backgroundColor: "#291084",
                                                        margin: "0 38px",
                                                    }}
                                                ></div>
                                                <Button
                                                    style={{
                                                        textAlign: "center",
                                                        fontFamily: "Montserrat",
                                                        fontSize: "24px",
                                                        fontStyle: "normal",
                                                        fontWeight: 500,
                                                        lineHeight: "40px",
                                                        letterSpacing: "1px",
                                                        textTransform: "uppercase",
                                                        color: "#fff",
                                                    }}
                                                >
                                                    Remove
                                                </Button>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </Modal>

                      </div>
                      <Typography style={{ ...webStyle.textStyle, position: 'absolute', top: 'calc(24% + 2px)', left: '50%', transform: 'translateX(-50%)' }}>
                          {userProfileDetails?.attributes?.full_name}
                      </Typography>
                  </div>
                  <Box  style={webStyle.fullNameTextWrapper}>
                    <Box style={{...webStyle.detailsInnerBoxWrapper, flexDirection: 'row'}}>
                      <div style={webStyle.settingDetailsWrapper}>
                      <img  src={fullNameUserIcon} width="24px" height="24px" alt="FullName Icon"  />
                      </div>
                      <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'flex-start', gap: '12px'}}>
                        <Typography style={{color:"#ADB1AD"}}>
                          Full Name
                        </Typography>
                        <Typography data-test-id="full-name">
                        {userProfileDetails?.attributes?.full_name}
                        </Typography>
                      </div>
                    </Box>
                    <div style={webStyle.lineStyle}></div>
                    <Box style={{...webStyle.detailsInnerBoxWrapper, flexDirection: 'row'}}>
                      <div style={webStyle.settingDetailsWrapper}>
                      <img src={profileMessageIcon} width="24px" height="24px" alt="Email"  />
                      </div>
                      <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'flex-start', gap: '12px'}}>
                        <Typography style={{color:"#ADB1AD"}}>
                          Email
                        </Typography>
                        <Typography >
                        {userProfileDetails?.attributes?.email}
                        </Typography>
                      </div>
                    </Box>
                    <div style={webStyle.lineStyle}></div>
                    <Box style={{...webStyle.detailsInnerBoxWrapper, flexDirection: 'row'}}>
                      <div style={{...webStyle.settingDetailsWrapper, backgroundColor:"#313131", borderRadius:"10px"}}>
                      <img src={getCountryFlag(userProfileDetails, countryCodeList)} width="24px" height="24px" alt="USA flag"  />
                      </div>
                      <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'flex-start', gap: '12px'}}>
                        <Typography style={{color:"#ADB1AD"}}>
                          Phone
                        </Typography>
                        <Typography >
                        {userProfileDetails?.attributes?.full_phone_number}
                        </Typography>
                      </div>
                    </Box>
                    <div style={webStyle.lineStyle}></div>
                    <Box style={{ padding: "24px 24px", width:"615px", height:"54px", display: 'flex', flexDirection: 'row', alignItems: 'flex-start', gap: '20px'}}>
                      <div style={webStyle.settingDetailsWrapper}>
                        <img src={ageGroupIcon} width="32px" height="32px" alt="age group" />
                      </div>
                        <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'flex-start', gap: '12px' }}>
                          <Typography style={{ color:"#ADB1AD" }}>
                            Age Group
                          </Typography>
                          <Typography >
                          {userProfileDetails?.attributes?.age_group}
                          </Typography>
                          
                        </div>
                        <div style={{ display: "flex", justifyContent: "center", alignItems: "center" }}>
                          <IconButton >
                            <img src={arrowDownIcon} width="32px" height="32px" alt="down-arrow" />
                          </IconButton>
                        </div>
                    </Box>
                    <div style={webStyle.lineStyle}></div>
                    <Box style={{ padding: "24px 24px", width:"615px", height:"54px", display: 'flex', flexDirection: 'row', alignItems: 'flex-start', gap: '20px'}}>
                      <div style={webStyle.settingDetailsWrapper}>
                        <img src={ageGroupIcon} width="32px" height="32px" alt="gender" />
                      </div>
                      <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'flex-start', gap: '12px' }}>
                        <Typography style={{ color:"#ADB1AD" }}>
                          Gender
                        </Typography>
                        <Typography >
                        {userProfileDetails?.attributes?.gender}
                        </Typography>
                      </div>
                      <div style={{ display: "flex", alignItems: "center", justifyContent: "center" }}>
                        <IconButton >
                          <img src={arrowDownIcon} height="32px" width="32px" alt="down-arrow" />
                        </IconButton>
                      </div>
                    </Box>
                    <div style={webStyle.lineStyle}></div>
                    <Box style={{ padding: "24px", width:"615px", height:"54px", display: 'flex', flexDirection: 'row', alignItems: 'flex-start', gap: '20px'}}>
                      <div style={webStyle.settingDetailsWrapper}>
                        <img src={earthIcon} height="25px" width="25px" alt="language group" />
                      </div>
                      <div style={{ display: 'flex', alignItems: 'flex-start', gap: '12px', flexDirection: 'column' }}>
                        <Typography style={{ color:"#ADB1AD" }}>
                          Language
                        </Typography>
                        <Typography >
                        {userProfileDetails?.attributes?.native_languages}
                        </Typography>
                      </div>
                      <div style={{ display: "flex", justifyContent: "center", alignItems: "center" }}>
                        <IconButton >
                          <img src={arrowDownIcon} width="32px" height="32px" alt="down-arrow-icon" />
                        </IconButton>
                      </div>
                    </Box>
                    <div style={webStyle.lineStyle}></div>
                    <Box style={{ padding: "24px", height:"54px", width:"615px", display: 'flex', flexDirection: 'row', alignItems: 'flex-start', gap: '20px'}}>
                      <div style={webStyle.settingDetailsWrapper}>
                        <img src={earthIcon} width="25px" height="25px" alt="country group" />
                      </div>
                      <div style={{ gap: '12px', display: 'flex', flexDirection: 'column', alignItems: 'flex-start' }}>
                        <Typography style={{ color:"#ADB1AD" }}>
                          Country
                        </Typography>
                        <Typography >
                        {userProfileDetails?.attributes?.country}
                        </Typography>
                      </div>
                      <div style={{ display: "flex", justifyContent: "center", alignItems: "center" }}>
                        <IconButton >
                          <img src={arrowDownIcon} width="32px" height="32px" alt="arrow-down-icon" />
                        </IconButton>
                      </div>
                    </Box>
                  
                    <div style={webStyle.editDetails}>
                      <div
                          style={{
                              backgroundColor: "#222",
                              padding: "5px",
                              borderRadius: "5px",
                          }}
                      >
                        <div
                            style={{
                              margin: "2px",
                              height:"136px",
                            }}
                        >
                          <Box style={{ padding: "15px 15px",  display: 'flex', flexDirection: 'row', alignItems: 'flex-start', }}>
                            <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'flex-start', gap: '12px' }}>
                               <div style={{ display: 'flex', flexDirection: 'row', gap: '12px' }}>
                                    <div style={webStyle.settingDetailsWrapper}>
                                        <img src={userSmileFill} width="25px" height="25px" alt="edit details" />
                                    </div>
                                    <Typography style={{ marginTop:"14px"}}>
                                    Edit Details
                                    </Typography>
                                </div> 
                                <div style={{ display: 'flex', flexDirection: 'row', gap: '12px' }}>
                                    <div style={webStyle.settingDetailsWrapper}>
                                        <img src={changePassword} width="25px" height="25px" alt="change password" />
                                    </div>
                                    <Typography style={{ marginTop:"14px"}} >
                                    Change Password
                                    </Typography>
                                </div> 
                            </div>
                            </Box>
                        </div>
                      </div>
                    </div>
                  </Box>
                </div> 
            </div>
        </div>
                </Modal>}
        
        </div>
    );
  }
}

const theme = createTheme({
    palette: {
      primary: {
        main: "#fff",
        contrastText: "#fff",
      },
    },
    typography: {
      h6: {
        fontWeight: 500,
      },
      subtitle1: {
        margin: "20px 0px",
      },
    },
  });
  
  const webStyle = {
    SelectCoverImage: {
        top: "20%",
        position: 'absolute',
        color: "#FFFFFF",
        width: "54%",
        margin: "0 20%",
        borderRadius: "10px",
        padding: "20px",
    
    } as const,
    personalInfoHeading: {
        fontFamily: "Inter",
        fontSize: "16px",
        fontStyle: "normal",
        fontWeight: 400,
        lineHeight: "24px",
    } as React.CSSProperties,
    editDetailsText:{
        fontFamily: "Poppins",
        fontSize: "16px",
        fontStyle: "normal",
        fontWeight: 400,
        lineHeight: "24px",
    },
    settingBaseWrapper: {
        backgroundColor: "#3900BD",
        borderRadius: "5px",
        width: "auto",
        height: "255px",
        textAlign: "center",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
    } as const,
        textStyle: {
        fontFamily: 'Poppins',
        fontSize: '24px',
        fontStyle: 'normal',
        fontWeight: 500,
        lineHeight: '24px',
        color: '#fff',
        marginTop: '19px', 
    },
    fullNameTextWrapper: {
        backgroundColor: "#212121",
        borderRadius: "5px",
        width: "auto",
        height: "110px",
    },
    lineStyle: {
        width: 'auto',
        height: '1px',
        backgroundColor: '#393929',
        margin: '0px 24px',
    },
    settingDetailsWrapper: {
        width:"50px", 
        height:"50px", 
        display: "flex", 
        justifyContent: "center", 
        alignItems: "center",
    },
    detailsInnerBoxWrapper: {
        padding: "24px 24px", width:"230px", height:"54px", display: 'flex', alignItems: 'flex-start', gap: '20px'
    },
    editDetails: {
        color: "#FFFFFF",
        borderRadius: "10px",
        width: "100%",
        margin: "5% 0%",

    },
    profileIcon:{
        width: "45px",
        cursor: "pointer",
        height: "45px",
        borderRadius:"120px",
        marginLeft:"14px",
        marginRight:"44px",
    },
    profileIconMember: {
        width: "45px",
        cursor: "pointer",
        height: "45px",
        borderRadius:"120px",
        marginLeft:"10px",
        marginRight:"22px"
    },
    dialogContainer:{
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        height: '300px',
        width:'5000px !important',
    },
    dialogContent:{
        width: '600px',
        backgroundColor: 'blue',  
    },

  }

