import React from "react";

import {
  Box,
  Grid,
  ListItemIcon,
  Typography,

  // Customizable Area Start
  // Customizable Area End
} from "@material-ui/core";

// Customizable Area Start

import List from '@material-ui/core/List';

import { Cover, LockIcon, statusIcon, playBtn, CheckIcon } from "./assets";
import CommunitylandingpageController, {
  Props,
} from "./CommunitylandingpageController.web";
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import Card from '@material-ui/core/Card';
import CardActions from '@material-ui/core/CardActions';
import CardContent from '@material-ui/core/CardContent';
import CardHeader from '@material-ui/core/CardHeader';
import Button from '@material-ui/core/Button';
import Modal from '@material-ui/core/Modal';
import IconButton from '@material-ui/core/IconButton';
import Avatar from '@material-ui/core/Avatar';
import MoreVertIcon from '@material-ui/icons/MoreVert';
import MoreHorizIcon from '@material-ui/icons/MoreHoriz';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';

import FavoriteIcon from '@material-ui/icons/Favorite';
import TelegramIcon from '@material-ui/icons/Telegram';
import Backdrop from "@material-ui/core/Backdrop";
import CreatorNavigationMenu from "../../navigationmenu/src/CreatorNavigationMenu.web";
import NavigationHeader from "../../navigationmenu/src/NavigationHeader.web";
import { isChrome } from 'react-device-detect';
// Customizable Area End



export default class Communitylandingpage extends CommunitylandingpageController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }

  // Customizable Area Start
  getAttributesItem = (key: any) => {
    const result = this.state.communityResponse && this.state.communityResponse.attributes && this.state.communityResponse.attributes[key]
    return result || "";
  }
  getAttributesWelcomeNote = (key: any) => {
    const result = this.state.welocomeNoteModalDetails && this.state.welocomeNoteModalDetails.attributes && this.state.welocomeNoteModalDetails.attributes[key]
    return result || "";
  }
  getCardBackgroundColor(index: any, isCompleted: any) {
    if (index === 0) {
      return "#C8158B";
    } else if (isCompleted) {
      return "#3E01B9";
    } else {
      return "#222222";
    }
  }
  getCardIcon(index: any, isCompleted: any) {
    if (index === 0) {
      return null; // or any other default value
    } else if (isCompleted) {
      return <img src={CheckIcon} width={18} height={18} alt="Completed"></img>;
    } else {
      return <img src={LockIcon} width={18} height={18} alt="Locked"></img>;
    }
  }

  renderVideo() {
    return <>
      <video
        ref={this.vedioControlRef}
        width="100%"
        style={{ borderRadius: "10px", backgroundColor: "black", maxHeight: "400px", objectFit: 'cover' }}
        src={this.state.src}
        data-testId="videoElement"
      >
      </video>
      <img src={playBtn} onClick={this.handleVedioControls} style={{ zIndex: 1, width: "7%", marginBottom: "-3rem", marginTop: "-2.5rem", marginLeft: "90%" }} data-test-id="testVedioControls" />
    </>
  }


  // Customizable Area End

  render() {
    let { isDragOver, imageUrl} = this.state;
    return (
      // Customizable Area Start

      <div >
            <NavigationHeader navigation={this.props.navigation} id="" />
        <Box style={{ backgroundColor: "#090909", display: "flex", height:"1618px" }}>
          <CreatorNavigationMenu navigation={undefined} id="" />
          <Grid item md={10} lg={10} style={{ color: "white", backgroundColor: "#090909" }}>
            <Grid item style={{
              display: "flex", flexDirection: "column",
              height: "480px"
            }}>

              {isChrome && this.state.src ?
                this.renderVideo() :
                <Box style={{ ...webStyle.Coverimage }}>
                </Box>
              }
              <div style={{
                display: "flex",
                justifyContent: "space-between"
              }}>
                <Box sx={{
                  alignSelf: "center",
                  marginLeft: "1.2em",
                }}>
                  <Typography style={{ display: "flex", alignItems: "center" }}>Status: <span style={{
                    boxSizing: "border-box",
                    padding: "5px",
                    display: "flex",
                    alignItems: "center",
                    backgroundColor: "#DFD1AB",
                    borderRadius: "10px",
                    color: "#DC5E17",
                    fontFamily: "Poppins",
                    marginLeft: "0.5em"

                  }}><img src={statusIcon} alt="Status" style={{ marginRight: "5px" }} />
                    {this.getAttributesItem("status")}
                  </span>
                  </Typography>
                </Box>
                <Box style={webStyle.Coverimagebottom}>

                  <img
                    src={this.getAttributesItem("cover_pic")}
                    style={webStyle.bottomIcon}></img>
                    <Typography variant="subtitle1" style={{...webStyle.fontMontserrat,fontSize: "18px", fontWeight: 600 ,}}> {this.getAttributesItem("name")}</Typography>
                  <Typography variant="caption" style={{...webStyle.fontInter,fontSize: "12px", fontWeight: 700 }} >@{this.getAttributesItem("username")}</Typography>

                </Box>

              </div>




            </Grid>
            <Grid item md={10}>
              <Box style={webStyle.ContentBox}>

                <Typography variant="h6" style={{ fontSize: "24px", fontWeight: 600 }}>About</Typography>
                <Typography variant="caption" style={{...webStyle.fontInter,fontWeight: 700}}>
                  {this.state.expanded ? this.getAttributesItem("description") : `${this.getAttributesItem("description").slice(0, 130)} `}
                  {this.getAttributesItem("description").length > 180 && (
                    <span style={{ color: 'white', cursor: 'pointer', textDecoration: "underline" }} onClick={this.toggleReadMore} data-test-id="testReadMore" id="readBox">
                      {this.state.expanded ? ' Read Less' : ' Read More'}
                    </span>
                  )}
                </Typography>
                <Typography variant="h6" style={{ fontSize: "24px", fontWeight: 600, marginTop: "20px" }}>Welcome, {this.getAttributesItem("creator")} </Typography>
                <Typography variant="caption">Complete your three Steps to start with your commucommunity.
                </Typography>
                <Typography variant="h6" style={{ fontSize: "24px", fontWeight: 600, marginTop: "20px" }}>0
                  {this.state.Cards.filter((cardItem) => cardItem.isCompleted === true).length + 1}{" "}
                  <span style={{ fontSize: "14px", fontWeight: 400 }}>Step</span></Typography>
              </Box>
              <Box style={{ display: "flex", flexWrap: "wrap", paddingLeft: "14px", width: "100%" }}>
                {[...this.state.Cards.filter((item) => item.isCompleted == false), ...this.state.Cards.filter((item) => item.isCompleted == true)].map((data: any, index: any) => {
                  return (
                    <Box key={index} style={{ margin: "0px 10px" }}>
                      <Box style={{...webStyle.Cards,backgroundColor : "none !important"}}>
                        <div style={{
                          display: "flex",
                          flexDirection: "column",
                          justifyContent: "space-between", height: "100%",
                          borderRadius: "10px",
                          backgroundColor: this.getCardBackgroundColor(index, data.isCompleted)
                        }}>
                          <CardContent>
                            <div style={{ display: "flex", justifyContent: "space-between" }}>
                              <img src={data.img} width={24} height={24}></img>
                              {this.getCardIcon(index, data.isCompleted)}
                            </div>

                            <Typography variant="body2" style={{ marginTop: "20px", fontSize: "12px" }}>
                              {data.description}
                            </Typography>
                          </CardContent>
                          <CardActions>

                            <button test-ID={"Cardbtn"} onClick={(item: any) => this.handleOpen(data.id,index,data.isCompleted)} style={webStyle.Cardbuttons}>{data.buttoncontent}</button>
                          </CardActions>

                        </div>

                      </Box>
                    </Box>
                  )
                })

                }
              </Box>



              <Modal
                open={this.state.Open}
               // onClose={this.handleClose}
                disableScrollLock={false}
                style={{ overflowY: "scroll" }}
              >
                <>

                  {this.state.switch === 3 ? (

                    <div data-testId="testSecondCard">
                      <this.ForNewPost onClose={() => {
                        this.setState({
                          Open:false
                        })
                      }} data-test-id="testSecondNewPost"/>

                    </div>

                  ) :
                    this.Othercards({imageUrl, isDragOver, video: this.state.video})
                  }

                </>

              </Modal>

              <Modal
                open={this.state.postModal}
               onClose={this.handleClosepostmodal}
                disableScrollLock={false}
                style={{ overflow: "auto" }}
                data-test-id = "testForNewPost2"
              >
                <this.ForNewPost onClose={() => {
                        this.setState({
                          Open:false,
                          postModal:false
                        })
                      }}
                      data-test-id = "testForNewPost2Close"
                      />
              </Modal>



              {this.state.textcard && (
                <Card style={{ width: "700px", marginLeft: "24px" }} test-Id="actionCardTest">
                  <CardHeader
                    test-Id="cardHeaderTest"
                    avatar={
                      <Avatar aria-label="recipe">
                        <img
                          src={this.getAttributesWelcomeNote("cover_pic")}
                          alt="Cover Pic"
                          style={{ width: '100%', height: '100%', objectFit: 'cover' }}
                        />
                      </Avatar>
                    }
                    action={
                      <div>

                        <IconButton aria-label="more"
                          aria-controls="long-menu"
                          aria-haspopup="true"
                          data-test-id="iconButtontest"
                          onClick={(event: any) => this.handleanchorElopen(event)}>
                          <MoreVertIcon />
                        </IconButton>
                        <Backdrop
                          style={{
                            background: "transparent",
                            zIndex: 1300,
                          }}
                          open={this.state.anchorEl}
                          onClick={() =>
                            this.setState({ anchorEl: false })
                          }
                          data-test-id="sortingwebBtn"
                        >
                          <Menu
                            id="long-menu"
                            anchorEl={this.state.anchorEl}
                            keepMounted
                            open={Boolean(this.state.anchorEl)}
                            onClose={() => this.handleanchorEl}
                            data-test-id="menuTestId"
                          >

                            <MenuItem onClick={this.handleanchorElOptions}>
                              Edit
                            </MenuItem>
                            <MenuItem data-test-id="welcomeDeleteBtnTest" onClick={this.handleWelcomeNoteDelete}>Delete</MenuItem>

                          </Menu>
                        </Backdrop>
                      </div>
                    }
                    // title="Shrimp and Chorizo Paella"
                    title={`${this.getAttributesWelcomeNote("username")}`}
                  />
                  <CardContent>
                    <Typography variant="body2" style={{ color: "#1C1C1C", fontSize: "16px" }} component="p">
                      {this.state.welcomeNoteText}
                    </Typography>
                  </CardContent>
                </Card>
              )}
            </Grid>
          </Grid>
        </Box>
      </div>

      // Customizable Area End
    );
  }
}

// Customizable Area Start
export const text = "Join the Interaction Designer Community for Insights , resources and inspiration to create  eng"
export const webStyle = {
  uploadphotobtn: {
    backgroundColor: "#3E01B9",
    color: "#FFFFFF",
    borderRadius: "5px",
    padding: "5px 10px",
    fontWeight: 700
  },
  IconButtoncard: {
    backgroundColor: "rgba(56, 56, 56, 1)",
    borderRadius: "30px",
    color: "#FFF",
    padding: "5px",
    marginRight: "10px"
  },
  Postbtn: {
    color: "grey",
    borderRadius: "0px",
    width: "100%",
    padding: "40px 0px"

  }, ButtonGroup: {
    display: "flex",
    // justifyContent: "space-between"
    gap :"10px"
  },
  cardmedia: {
    width: "80%",
    margin: "auto"
  },
  Postcardstypo: {
    fontSize: "14px",
    fontWeight: 400
  },
  PostButtons: {
    borderRadius: "20px",
    fontWeight: 600,
    color: "#FFF",

  },
  dropzone: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    height: "100%"
  },
  MODAL_STYLES: {
    position: "absolute",
    backgroundColor: "#FFF",
    padding: "15px",
    zIndex: "1000",
    width: "35%",
    borderRadius: ".5em"
  } as const,
  OVERLAY_STYLE: {
    position: "fixed",
    display: "flex",
    justifyContent: "center",
    top: "0",
    left: "0",
    width: "100%",
    height: "100%",
    backgroundColor: "rgba(0,0,0, .8)",
    zIndex: "1000",
    overflowY: "auto"
  } as const,
  margins: {
    margin: "0px",
    backgroundColor: "fff",
    alignItems: "flex-start"
  },
  userProfileWrapper: {
    display: "flex",
    flexDirection: "column" as "column",
    justifyContent: "center",
    alignItems: "center",
    paddingTop: 30,
  },
  modalcontent: {
    fontSize: "14px",
    fontWeight: 400,
    color: "#E8E8E8"
  },
  Cardbuttons: {
    fontSize: "12px",
    textDecoration: "underline",
    color: "white",
    fontWeight: 600,
    background: "none",
    border: "none",
    cursor: "pointer",
  },
  Cards: {
    borderRadius: "10px",

    width: "220px",
    height: "200px",
    color: "white",

    marginBottom: "20px"
  } as const,
  modalStyle: {
    top: "20%",
    position: 'absolute',
    color: "#FFFFFF",
    width: "60%",
    margin: "0 15%",
    backgroundColor: "#383838",
    display: "flex",
    justifyContent: "center",
    borderRadius: "10px",
    textAlign: "center",
  } as const,
  SelectCoverImage: {
    top: "20%",
    position: 'absolute',
    color: "#FFFFFF",
    width: "70%",
    margin: "0 15%",

    borderRadius: "10px",
    padding: "20px",

  } as const,
  userProfile: {
    width: 48,
    height: 48
  },
  drawerItemIcon: {
    width: 20,
    height: 20,
    selfAlign: "center",
    marginLeft: 7,
  },
  ContentBox: {
    padding: "0px 24px",
    width: "60%"
  },
  bottomIcon: {
    height: "48px",
    width: "48px",
    marginTop: "-24px"
  },
  drawerItemtext: {
    color: "#FFFFFF",
    fontWeight: 700,
    whiteSpace: "nowrap",
    overflow: "hidden",
    textOverflow: "ellipsis"
  } as const,
  Coverimage: {
    backgroundImage: `url(${Cover})`,
    height: "400px", backgroundRepeat: 'no-repeat', backgroundSize: "cover"
  } as const,
  Coverimagebottom: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "flex-end",
    position: "relative",
    marginRight: "35em",
  } as const,
  drawerItemList: {
    paddingTop: 0,
    paddingBottom: 0
  },
  dsiplayNone: {
    display: "none"
  },
  divAlign: {
    border: "2px solid Black",
    backgroundColor: "#fff",
    borderRadius: "10px",
    padding: "15px"
  },
  divImg: {
    height: "auto",
    width: "100%"
  },
  AlignImg: {
    width: "100%",
    height: 200,
    borderRadius: "10px"
  },
  Closebtn: {
    position: "absolute",
    right: "25%",
    backgroundColor: "#fff"
  } as const,
  Droptext: {
    color: "#C4C4C4",
    fontSize: "14px",
    fontWeight: 500
  },
  innerBody: {
    border: "1px solid grey",
    borderRadius: "10px",
    borderStyle: "dashed",
    height: "150px",
    display: "flex",
    flexDirection: "column",
    justifyContent: "center"

  } as const,
  fontPoppins :{
    fontFamily : "Poppins,sans-serif",
  },
  fontMontserrat :{
    fontFamily: "Montserrat,sans-serif",
  },
  fontInter : {
    fontFamily : "Inter,sans-serif",
  },
}


// Customizable Area End
