import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";
import { getStorageData } from "../../../framework/src/Utilities";

// Customizable Area Start
// Customizable Area End

export const configJSON = require("./config");

export interface Props {
  navigation: any;
  id: string;
  // Customizable Area Start
  classes: any
  // Customizable Area End
}

interface S {
  // Customizable Area Start
  activeStep: number,
  category: any
  categoryList: any
  categoryData: any
  topCreator: any
  trendingCommunity: any
  FeaturedCommunities: any
  VisibleCategory:any
  // Customizable Area End
}

interface SS {
  id: any;
}

export default class DiscoverPageMemberController extends BlockComponent<
  Props,
  S,
  SS
> {
  apiCommunityListId: any
  apiCommunityDataId: any
  apiTopCreatorId: any
  apiTrendingCommunitiesId: any
  apiFeaturedCommunitiesId: any
  apiVisibleCategory:any
  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);

    // Customizable Area Start
    this.subScribedMessages = [
      getName(MessageEnum.RestAPIResponceMessage),
    ];
    this.state = {
      activeStep: 0,
      // Customizable Area Start
      category: "",
      categoryList: [],
      categoryData: [],
      topCreator: [],
      trendingCommunity: [],
      FeaturedCommunities: [],
      VisibleCategory:[]
      // Customizable Area End
    };
    // Customizable Area End
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
  }
  async receive(from: string, message: Message) {
    // Customizable Area Start
    if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
      const apiRequestCallId = message.getData(
        getName(MessageEnum.RestAPIResponceDataMessage)
      );

      const responseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );
      if (apiRequestCallId && responseJson) {
        if (apiRequestCallId === this.apiCommunityListId) {
          this.setState({
            categoryList: responseJson.data
          })
        } else if (apiRequestCallId === this.apiCommunityDataId) {
          this.handleSetCategoryData(responseJson)
        } else if (apiRequestCallId === this.apiTopCreatorId) {
          this.setState({
            topCreator: responseJson.data
          })
        } else if (apiRequestCallId === this.apiTrendingCommunitiesId) {
          this.setState({
            trendingCommunity: responseJson.data
          })
        }
        else if (apiRequestCallId === this.apiFeaturedCommunitiesId) {
          this.setState({
            FeaturedCommunities: responseJson.data
          })
        }else if (apiRequestCallId ===this.apiVisibleCategory){
          this.setState({
            VisibleCategory: responseJson.data
          })
        }
      }
    }
    runEngine.debugLog("Message Recived", message);
    // Customizable Area End
  }
  async componentDidMount() {
    this.getCategoryList()
    this.handleGetTopCreatorsApi()
    this.handleGetTopTrendingApi()
    this.handleGetFeaturedCommunitiesApi()
    this.handleGetVisibleCategoryApi()
  }
  // Customizable Area Start

  handleNavigatetoDiscover = () => {
    this.props.navigation.navigate('DiscoverPageMember');
  }

  handleSetCategoryData = (responseJson: any) => {
    if (responseJson.data) {
      this.setState({
        categoryData: responseJson.data
      })
    } else {
      this.setState({
        categoryData: []
      })
    }
  }

  handleChangeStep = (step: number) => {

    this.setState({ activeStep: step })
  };

  async getCategoryList() {
    const token = await getStorageData("authToken")

    const endPoint = `/bx_block_categories/categories`;

    const header = {
      token: token
    };
    const rcategoryListMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.apiCommunityListId = rcategoryListMessage.messageId;

    rcategoryListMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      endPoint
    );

    rcategoryListMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      "GET"
    );
    rcategoryListMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    runEngine.sendMessage(rcategoryListMessage.id, rcategoryListMessage);
  }
  async handleGetCategoryDataApi(data: any) {

    const token = await getStorageData("authToken")
    const endPoint = `/bx_block_communityforum/find_category_all_communities?category_id=${data.id}`;

    const header = {
      "Content-Type": "application/json",
      token: token
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.apiCommunityDataId = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      endPoint
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      "GET"
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);

  }
  async handleGetTopCreatorsApi() {
    const token = await getStorageData("authToken")

    const endPoint = '/bx_block_trending/top_creators';

    const header = {
      "Content-Type": "application/json",
      token: token
    };

    const topCreatorMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.apiTopCreatorId = topCreatorMessage.messageId;

    topCreatorMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      endPoint
    );

    topCreatorMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    topCreatorMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      "GET"
    );
    runEngine.sendMessage(topCreatorMessage.id, topCreatorMessage);

  }
  async handleGetTopTrendingApi() {

    const token = await getStorageData("authToken")

    const endPoint = '/bx_block_trending/trending_communities';

    const header = {
      "Content-Type": "application/json",
      token: token
    };

    const trendingMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.apiTrendingCommunitiesId = trendingMessage.messageId;

    trendingMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      endPoint
    );

    trendingMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    trendingMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      "GET"
    );
    runEngine.sendMessage(trendingMessage.id, trendingMessage);

  }
  async handleGetFeaturedCommunitiesApi() {

    const token = await getStorageData("authToken")

    const endPoint = '/bx_block_communityforum/featured_communities';

    const header = {
      "Content-Type": "application/json",
      token: token
    };

    const FeaturedCommunitiesMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.apiFeaturedCommunitiesId = FeaturedCommunitiesMessage.messageId;

    FeaturedCommunitiesMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      endPoint
    );

    FeaturedCommunitiesMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    FeaturedCommunitiesMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      "GET"
    );
    runEngine.sendMessage(FeaturedCommunitiesMessage.id, FeaturedCommunitiesMessage);

  }
  async handleGetVisibleCategoryApi() {

    const token = await getStorageData("authToken")

    const endPoint = '/bx_block_categories/visible_categories';

    const header = {
      "Content-Type": "application/json",
      token: token
    };

    const VisibleCategoryMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.apiVisibleCategory = VisibleCategoryMessage.messageId;

    VisibleCategoryMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      endPoint
    );

    VisibleCategoryMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    VisibleCategoryMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      "GET"
    );
    runEngine.sendMessage(VisibleCategoryMessage.id, VisibleCategoryMessage);

  }
  handleGetCategoryData = async (data: any) => {
    this.setState({
      category: data.attributes.name
    })
    this.handleGetCategoryDataApi(data)
  }
  // Customizable Area End
}
