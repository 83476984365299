export const imgPasswordVisible = require("../assets/ic_password_visible.png");
export const imgPasswordInVisible = require("../assets/ic_password_invisible.png");
export const userSmileFill = require("../assets/user-smile-fill.png");
export const editIcon = require("../assets/editIcon.png");
export const fullNameUserIcon = require("../assets/fullNameUserIcon.png");
export const USAflag = require("../assets/USA_flag.png");
export const ageGroupIcon = require("../assets/age_group_icon.png");
export const arrowDownIcon = require("../assets/arrow-down_icon.png");
export const profileIcon = require("../assets/profile_pic.png");
export const earthIcon = require("../assets/earthIcon.png");
export const profileMessageIcon = require("../assets/profileMessageIcon.png");
export const changePassword = require("../assets/Password.png");