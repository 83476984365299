import React from "react";

import {
  // Customizable Area Start
  Typography,
  Box,
  createStyles,
  createTheme,
  ThemeProvider,
  Theme,
  withStyles,
  Tooltip
  // Customizable Area End
} from "@material-ui/core";

// Customizable Area Start
import AboutUsController, { Props, formatNumber } from "./AboutUsController";
import { Footer, WaitingSection } from "./LandingPage.web";
import { ReviewsWithStyles } from "./Reviews.web";
import KeyBenefits from "./KeyBenefits/KeyBenefits.web";
import NavigationHeader from "../../navigationmenu/src/NavigationHeader.web";
// Customizable Area End

// Customizable Area Start
const theme = createTheme({
  typography: {
    fontFamily: "Montserrat",
    h1: {
      color: "#FFF",
      textAlign: "center",
      fontSize:"4em",
      fontWeight: 500,
      lineHeight: "normal",
      letterSpacing: "0.123px",
    },
    h2: {
      color: "#E8E8E8",
      textAlign: "center",
      fontSize: "3.2em",
      fontStyle: "normal",
      fontWeight: 600,
      lineHeight: "40px",
      letterSpacing: "0.123px",
      textTransform: "capitalize",
    },
    h3: {
      color: "#E8E8E8",
      fontSize: "1.8em",
      fontStyle: "normal",
      fontWeight: 600,
      lineHeight: "24px",
      letterSpacing: "0.123px",
      textTransform: "capitalize",
    },
    subtitle1: {
      color: "#E8E8E8",
      fontSize: "1.6em",
      fontStyle: "normal",
      fontWeight: 500,
      lineHeight: "24px",
      letterSpacing: "0.123px",
      opacity: 0.5,
    },
    subtitle2: {
      color: "#E8E8E8",
      fontSize: "1.4em",
      fontStyle: "normal",
      fontWeight: 400,
      lineHeight: "26px",
      opacity: 0.7,
    },
  },
});
const otheme=createTheme({})
interface IProps {
  classes: any;
  members:number;
  communities:number;
  views:number;
  data:any;
}

interface DProps {
  classes: any;
  data:any
}
const AboutZillion: React.FunctionComponent<IProps> = (props) => {
  const { classes,data,members,communities,views } = props;
  let d;
  if(data.length>0){
      d=data[0];
  }
  else{
    return null;
  }
  const {attributes}=d
  const {formattedNum:communitiesNum,text:communitiesText}=formatNumber(communities)
  const {formattedNum:viewsNum,text:viewsText}=formatNumber(views)
  const {formattedNum:membersNum,text:membersText}=formatNumber(members)
  return (
    <Box className={classes.aboutZillionParentContainer}>
      <Box className={classes.aboutZillionParent}>
        <Box className={classes.aboutZillionHeading}>
          <Typography className={classes.aboutZillionBtn}>
            About Zillion
          </Typography>
          <Typography variant="h1" dangerouslySetInnerHTML={{ __html: attributes.heading }}/>
          <Typography variant="subtitle1">
           {attributes.description}
          </Typography>
        </Box>
        <Box mt={7}>
          <img
            src={attributes.image}
            alt="about zillion"
            className={classes.aboutZillionImg}
          />
        </Box>
        <Box className={classes.aboutZillionCardParent} mt={2}>
          <Box className={classes.aboutZillionCard}>
            <Typography className={classes.cardTitle}>{membersNum}+</Typography>
            <Typography className={classes.cardDesc}>
              Over {membersText} members in the Zilion community
            </Typography>
          </Box>
          <Box className={classes.aboutZillionCard}>
            <Typography className={classes.cardTitle}>{viewsNum}+</Typography>
            <Typography className={classes.cardDesc}>
              Creative work {viewsText} times viewed on Zilion
            </Typography>
          </Box>
          <Box className={classes.aboutZillionCard}>
            <Typography className={classes.cardTitle}>{communitiesNum}+</Typography>
            <Typography className={classes.cardDesc}>
              Over {communitiesText} community in the Zilion community
            </Typography>
          </Box>
        </Box>
      </Box>
    </Box>
  );
};

const MoneySection: React.FunctionComponent<DProps> = (props) => {
  const { classes,data } = props;
  return (
    <Box className={classes.container}>
      <Box className={classes.aboutMoneyParent}>
        <Typography variant="h2">How creators make money on Zilion</Typography>
        <Typography
          variant="subtitle1"
          className={classes.aboutMoneySubtitle}
        >
          Zilion is a platform that empowers creators to build and grow their
          businesses. It offers a variety of ways for creators to make money,
        </Typography>
        <Box className={classes.aboutZillionCardParent} mt={6}>
          {data.map((item: any) => {
            const { attributes } = item;
            return (
              <Box className={classes.aboutMoneyCard} key={item.id}>
                <img
                  src={attributes.image}
                  alt=""
                  className={classes.aboutMoneyImg}
                />
                <Typography variant="h3">{attributes.heading}</Typography>
                <Tooltip title={attributes.description} arrow>
                  <Typography variant="subtitle2" className={classes.moneyDesc}>
                     {attributes.description}
                  </Typography>
                </Tooltip>
              </Box>
            );
          })}
        </Box>
      </Box>
    </Box>
  );
};

const ConnectSection: React.FunctionComponent<DProps> = (props) => {
  const { classes,data } = props;
  let d;
  if(data.length>0){
      d=data[0];
  }
  else{
    return null;
  }
  const {attributes}=d
  return (
    <Box className={classes.container}>
      <Box className={classes.connectParent}>
        <img
          src={attributes.image}
          alt="connect section"
          className={classes.connectSectionImg}
        />
        <Box className={classes.connectDesc}>
          <Typography variant="h2" style={{ textAlign: "left" }}>
            {attributes.heading}
          </Typography>
          <Typography variant="subtitle2">
            {attributes.description}
          </Typography>
          <Typography variant="subtitle2">
           {attributes.sub_description}
          </Typography>
        </Box>
      </Box>
    </Box>
  );
};

const CommunityAndFansSection: React.FunctionComponent<DProps> = (props) => {
  const { classes,data } = props;
  let d;
  if(data.length>0){
      d=data[0];
  }
  else{
    return null;
  }
  const {attributes}=d
  return (
    <Box className={classes.container}>
      <Box className={classes.connectParent}>
        <Box className={classes.connectDesc}>
          <Typography variant="h2" style={{ textAlign: "left" }}>
           {attributes.heading}
          </Typography>
          <Typography variant="subtitle2">
           {attributes.description}
          </Typography>
          <Typography variant="subtitle2">
            {attributes.sub_description}
          </Typography>
        </Box>
        <img
          src={attributes.image}
          alt="connect section"
          className={classes.connectSectionImg}
        />
      </Box>
    </Box>
  );
};

// Customizable Area End

export default class AboutUs extends AboutUsController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }

  // Customizable Area Start

  // Customizable Area End

  render() {
    return (
      <ThemeProvider theme={otheme}>
        <NavigationHeader navigation={this.props.navigation} id="" isNewUser={true} />
        <Box className={this.props?.classes.aboutUsParent}>
          <ThemeProvider theme={theme}>
            <AboutZillion classes={this.props?.classes} data={this.state.mainSec} members={this.state.members} communities={this.state.communities} views={this.state.views}/>
            <MoneySection classes={this.props?.classes} data={this.state.moneySec}/>
            <ConnectSection classes={this.props?.classes} data={this.state.connectSec} />
          </ThemeProvider>
          <KeyBenefits type="main" isCenter={true} padding="80px 135px"/>
          <ThemeProvider theme={theme}>
            <CommunityAndFansSection classes={this.props?.classes} data={this.state.fansSec} />
            <ReviewsWithStyles type="main" id="reviews" navigation={undefined}/>
          </ThemeProvider>
          <WaitingSection onSignUp={this.OnSignUp}/>
          <Footer />
        </Box>
      </ThemeProvider>
    );
  }
}

// Customizable Area Start
export const styles = (theme:Theme) =>
  createStyles({
    aboutUsParent: {
      display: "flex",
      flexDirection: "column",
      background: "#000",
      fontSize:"10px"
    },
    aboutZillionParentContainer:{
      display: "flex",
      flexDirection: "column",
      background: "#1C1C1C",
      justifyContent:"center",
      alignItems:"center"
    },
    aboutZillionParent: {
      padding: "80px 135px",
      maxWidth:"1280px"
    },
    aboutZillionBtn: {
      padding: "8px 16px",
      borderRadius: "50px",
      background: "#B50B8F",
      color: "#fff",
      fontSize: "1.6em",
      fontWeight: 400,
      textTransform: "capitalize",
      width: "fit-content",
    },
    aboutZillionImg: {
      width: "100%",
      borderRadius: "64px",
      background: "linear-gradient(180deg, #F1F1F1 0%, #F3F3F3 21.05%, #FCFCFC 47.88%, #FCFCFC 61.61%, #F4F4F4 67.03%, #F6F6F6 71.25%, #F7F7F7 73.67%, #FFF 76.02%, #FDFDFD 77.52%, #F0F0F0 83.19%, #F2F2F2 86.29%, #E8E8E8 89.61%, #E5E5E5 92.96%, #E4E4E4 100%)",
      [theme.breakpoints.down('sm')]: {
        borderRadius: "24px",
      },
    },
    aboutZillionHeading: {
      padding: "0px 94px 0px 94px",
      display: "flex",
      flexDirection: "column",
      justifyContent: "center",
      alignItems: "center",
      gap: "24px",
      textAlign: "center",
    },
    aboutZillionCardParent: {
      display: "flex",
      gap: "24px",
      justifyContent: "center",
      [theme.breakpoints.down('sm')]: {
        flexDirection:"column"
      },
    },
    aboutZillionCard: {
      display: "flex",
      padding: "40px",
      flexDirection: "column",
      justifyContent: "center",
      alignItems: "center",
      gap: "16px",
      borderRadius: "24px",
      background: "rgba(255, 255, 255, 0.10)",
      [theme.breakpoints.down('sm')]: {
        padding:"15px",
        gap:"10px"
      },
    },
    cardTitle: {
      color: "#FFF",
      fontFamily: "Montserrat",
      fontSize: "3.6em",
      fontStyle: "normal",
      fontWeight: 600,
      lineHeight: "40px",
      letterSpacing: "0.123px",
      [theme.breakpoints.up('lg')]: {
        fontSize:"3.6em"
      },
      [theme.breakpoints.between('md','lg')]: {
        fontSize:"3em"
      },
      [theme.breakpoints.between('sm','md')]: {
        fontSize:"2.4em"
      },
      [theme.breakpoints.down('sm')]: {
        fontSize:"1.8em"
      },
    },
    cardDesc: {
      color: "#FFF",
      textAlign: "center",
      fontSize: "1.6em",
      fontStyle: "normal",
      fontWeight: 500,
      lineHeight: "24px",
      letterSpacing: "0.123px",
      [theme.breakpoints.up('sm')]: {
        fontSize:"1.6em"
      },
      [theme.breakpoints.down('sm')]: {
        fontSize:"1.4em"
      },
    },
    container:{
      display:"flex",
      flexDirection:"column",
      justifyContent:"center",
      alignItems:"center"
    },
    aboutMoneyParent: {
      padding: "80px 135px 0px 135px",
      display: "flex",
      flexDirection: "column",
      maxWidth:"1280px",
      alignItems:"center"
    },
    aboutMoneyCard: {
      display: "flex",
      flexDirection: "column",
      gap: "24px",
    },
    aboutMoneyImg:{
      borderRadius:"16px",
      maxWidth:"458px",
      maxHeight:"335px",
      width:"373px",
      height:"300px",
      [theme.breakpoints.down('lg')]: {
        width:"100%"
      },
    },
    moneyDesc:{
      display: "-webkit-box",
      WebkitLineClamp: 2,
      WebkitBoxOrient: "vertical",
      overflow: "hidden",
      textOverflow: "ellipsis",
    },
    aboutMoneySubtitle:{
      textAlign: "center",
      marginTop: "16px",
      maxWidth:"647px" 
    },
    connectParent: {
      padding: "80px 135px 80px 135px",
      display: "flex",
      gap: "124px",
      alignItems: "center",
      justifyContent: "space-between",
      maxWidth:"1280px",
      [theme.breakpoints.down('xs')]: {
        flexDirection:"column",
        gap:"80px",
      },
      
    },
    connectDesc: {
      display: "flex",
      flexDirection: "column",
      justifyContent: "flex-start",
      gap: "24px",
      width: "40%",
      [theme.breakpoints.down('xs')]: {
        width: "100%",
      },
    },
    connectSectionImg: {
      maxWidth: "473px",
      maxHeight: "572px",
      width:"100%"
    },
  });
const AboutUsWithStyles = withStyles(styles)(AboutUs);
export { AboutUsWithStyles };
// Customizable Area End
