import React from "react";

import {
  Box,
  Button,
  Typography,
  List,
  ListItem,
  ListItemText,
  Grid,
  Container
} from "@material-ui/core";

//@ts-ignore
import classes from "./HomePageMember.module.css";
import MemberNavigationMenu from "../../navigationmenu/src/MemberNavigationMenu.web";
import HomePageMemberController, { Props } from "../src/HomePageMemberController"
import { Info, Hero } from "./assets"
import NavigationHeader from "../../navigationmenu/src/NavigationHeader.web";
import FavoriteIcon from '@material-ui/icons/Favorite';
import ChatIcon from '@material-ui/icons/ChatBubbleRounded';
import SendIcon from '@material-ui/icons/Send';
import MoreIcon from '@material-ui/icons/MoreHoriz';
import LockIcon from '@material-ui/icons/Lock';
import ArrowOutward from '@material-ui/icons/ArrowUpward'
const navigate = require("react-navigation")

const eventData = [
  {
    title: 'How to become a tiktok creator',
    isLocked: false,
    isLiked: false,
  },
  {
    title: 'How to become a tiktok creator.',
    isLocked: false,
    isLiked: true,
  },
  {
    title: 'How to become a tiktok creator.',
    isLocked: false,
    isLiked: false,
  },
  {
    title: 'How to become a tiktok creator',
    isLocked: true,
    isLiked: false,
  },
  {
    title: 'How to become a tiktok creator.',
    isLocked: false,
  },
  {
    title: 'How to become a tiktok creator.',
    isLocked: true,
    isLiked: false,
  },
  {
    title: 'How to become a tiktok creator.',
    isLocked: false,
    isLiked: false,
  },
  {
    title: 'How to become a tiktok creator',
    isLocked: true,
    isLiked: false,
  },
  {
    title: 'How to become a tiktok.',
    isLocked: true,
    isLiked: false,
  },
  {
    title: 'How to become a tiktok creator',
    isLocked: false,
    isLiked: false,
  },
]

export const WelcomeCommunity = () => {
  return (
    <>
      <Box className={classes.communityWelcome}>
        <Box className={classes.communityTitle}>
          <Box className={classes.communityWelimg}>
            <img src={Hero} alt="Hero" />
          </Box>
          <Typography variant="h6">Welcome To <span>Interaction Designer</span> </Typography>
        </Box>
        <Box className={classes.communityWelcomeMsg}>
          <Typography>
            Hi, thanks for joining creative mindsets. I am vikram and I post everyday. 
          </Typography>
        </Box>
        <Box className={classes.communityTitleLink}>
          <Box className={classes.communityWelimg}>
            <img src={Hero} alt="Hero" />
          </Box>
          <Typography variant="h6">Visit <span>Interaction Designer Community Page</span> </Typography>
          <Box className={classes.communityArrow}>
            <ArrowOutward />
          </Box>
        </Box>
      </Box>
    </>
  )
}

export const CommunityListGrid = () => {
  return (
    <>
    <Box className={classes.communityTopWrap}>
        <Box className={classes.communityTopOne}>
          <Box className={classes.communityTopimg}>
            <img src={Hero} alt="Hero" />
          </Box>
          <Typography variant="h6">All <span>community</span> </Typography>
        </Box>
        <Box className={classes.communityImg}>
          <img src={Hero} alt="Hero" />
        </Box>
        <Box className={classes.communityImg}>
          <img src={Hero} alt="Hero" />
        </Box>
        <Box className={classes.communityImg}>
          <img src={Hero} alt="Hero" />
        </Box>
        <Box className={classes.communityImgAdd}>
          +
        </Box>
      </Box>
    </>
  )
}

export const FilterListNav = () => {
  return (
    <>
      <Box>
        <List className={classes.filterNav}>
          <ListItem className={classes.active}>
            <ListItemText primary="All Posts" />
          </ListItem>
          <ListItem className={classes.navListLink}>
            <ListItemText primary="Articles" />
          </ListItem>
          <ListItem className={classes.navListLink}>
            <ListItemText primary="Events" />
          </ListItem>
          <ListItem className={classes.navListLink}>
            <ListItemText primary="Audios" />
          </ListItem>
          <ListItem className={classes.navListLink}>
            <ListItemText primary="Videos" />
          </ListItem>
        </List>
      </Box>
    </>
  )
}

export const CardGridSection = ({ eventData, handleConfirmDetailsOpen }: any) => {
  return (
    <>
      <Box>
        <Container className={classes.cardGridContainer}>
          <Grid container spacing={2}>
            {
              eventData && eventData.map((item: any) => (
                <Grid item xs={6} sm={4} lg={4} xl={3}>
                  <EventCardBlock item={item} />
                </Grid>
              ))
            }
          </Grid>
        </Container>
      </Box>
    </>
  )
}

export const EventCardBlock = ({ event }: any) => {
  return (
    <>
      <Box className={`${classes.eventCardBlock} ${event?.isLocked ? classes.eventCardLock : ""}`}>
        <Box className={classes.eventmore}>
          <Typography variant="h6">6 Hour ago</Typography>
          <MoreIcon />
        </Box>
        <Box className={classes.eventCardHead}>
          <img src={Hero} alt="Hero" />
          <Typography variant="h6">Join your favourite community today with a 15%... Learn more</Typography>
        </Box>
        <Box className={classes.eventImg}>
          <img src={Hero} alt="Hero" />
        </Box>
        <Typography className={classes.titleText}>{event?.title}</Typography>
        <Typography className={classes.titleSubText}>Here we will learn the basic things you need to know to become a toktok creator, from looking for ideas to uploading videos</Typography>
        <Box className={classes.sendIconMain}>
          <Box className={classes.favIconSection}>
            <Box className={classes.favIconBlk}>
              <FavoriteIcon className={`${event?.isLiked ? classes.favIconBlkRed : ''}`} />
              <Typography>5.7K</Typography>
            </Box>
            <Box className={classes.favIconBlk}>
              <ChatIcon />
              <Typography>5.7K</Typography>
            </Box>
          </Box>
          <SendIcon />
        </Box>
        <Box className={`${event?.isLocked ? classes.eventCardLockContent : classes.eventCardUnlock}`}>
          <LockIcon />
          <Typography variant="subtitle1">Unlock this one by becoming the member</Typography>
          <Button variant="contained" className={classes.newPostBtn}>
            Join
          </Button>
        </Box>
      </Box>
    </>
  )
}

export default class HomePageMember extends HomePageMemberController {
  constructor(props: Props) {
    super(props);
  }
  render() {
    return (
      <div><NavigationHeader navigation={this.props.navigation} id="" isMemberUser={true} />
        <Box style={webStyle.home}>
          <MemberNavigationMenu {...this.props} />
          {!this.state?.communityList?.length ?
            <Box style={{ width: `calc(100vw - 295px)` }}>
              <Box style={{ display: "flex", alignItems: "center", flexDirection: "column", marginTop: "168px" }}>
                <Box style={{ display: "flex", justifyContent: "center" }}>
                  <Typography style={webStyle.text_color}>
                    <img src={Info} alt="Info" style={webStyle.info_icon} />
                    You haven't subscribed to any community yet. Support or follow communities to see posts in your feed.
                  </Typography>
                </Box>
                <Button style={webStyle.discover_button} onClick={this.handleNavigatetoDiscover}>Discover Communities</Button>
              </Box>
            </Box>
            :
            <Box className={classes.homeSecWrapper}>
              <Box className={classes.homeMainSection}>
                <WelcomeCommunity />
                <CommunityListGrid />
                <FilterListNav />
                <CardGridSection eventData={eventData}/>
              </Box>
            </Box>
          }
        </Box>
      </div>
    );
  }
}



const webStyle = {
    home: {
        backgroundColor: '#090909',
        display: "flex",
        minHeight: "100vh"
    },
    text_color: {
        color: "#FFFFFF",
        textAlign: "center" as const,
        fontSize: "22px",
        maxWidth: "725px",
        fontFamily: "Montserrat",
        opacity: "50%"
    },
    info_icon: {
        width: "26.67px",
        height: "26.67px",
        transform: 'translate(-5px,6px)'
    },
    discover_button: {
        width: "439px",
        height: "64px",
        backgroundColor: "#3E01B9",
        marginTop: "20px",
        fontSize: "18px",
        color: "#FFFFFF",
        fontWeight: 600,
        fontFamily: "Montserrat",
        textTransform: "none"
    } as React.CSSProperties,
    community: {
        width: `calc(100vw - 295px)`,
        height: "100vh",
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        color: "#ffffff",
        fontSize: "20px"
    },
    communityList_container: {
        display: "flex",
        alignItems: "center",
        flexDirection: "column",
        marginTop: "168px"
    } as React.CSSProperties,

};
// Customizable Area End
